<template>
  <nav class="topbar">
    <div class="center">
      <a
        :href="'/u/info'"
        v-bind:class="{ active: currentRoute.endsWith('info') }"
      >
        <h5>Stats</h5>
      </a>
      <a
        :href="'/u/edit'"
        v-bind:class="{ active: currentRoute.endsWith('edit') }"
      >
        <h5>Links</h5>
      </a>
      <a
        :href="'/u/settings'"
        v-bind:class="{ active: currentRoute.endsWith('settings') }"
      >
        <h5>Settings</h5>
      </a>
      <a
        class="show-for-small-below"
        :href="'/u/preview'"
        v-bind:class="{ active: currentRoute.endsWith('preview') }"
      >
        <h5>Preview</h5>
      </a>
    </div>
  </nav>
</template>

<script>
export default {
  computed: {
    currentRoute() {
      return this.$router.currentRoute.name;
    },
    me() {
      return this.$root.ssrData.profile;
    },
    route() {
      return this.$root.ssrData.path;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "colors.scss";
.topbar {
  align-items: center;
  background-color: #fff;
  box-shadow: inset 0 -1px 0 0 #e2e2ea;
  display: flex;
  height: 56px;
  justify-content: center;
  padding: 0 24px;
  position: fixed;
  top: 0;
  width: 50%;
  z-index: 1;

  @media screen and (max-width: 1200px) {
    top: 56px;
    width: 62.5%;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.active h5 {
  color: $color-accent-default;
}

.logotype {
  color: #44444f;
  font-size: 24px;
  font-weight: 600;
}

.center {
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: center;

  a {
    align-items: center;
    display: flex;
    font-size: 14px;
    font-weight: 600;
    height: 100%;
    padding: 5px 4px;
    position: relative;

    &.active:after {
      background: $color-accent-default;
      border-top-left-radius: 100px;
      border-top-right-radius: 100px;
      bottom: 0;
      content: "";
      height: 3px;
      left: 0;
      position: absolute;
      right: 0;
    }

    &.show-for-small-below {
      display: none;

      @media screen and (max-width: 768px) {
        display: flex;
      }
    }
  }

  a + a {
    margin-left: 48px;

    @media screen and (max-width: 1200px) {
      margin-left: 24px;
    }
  }

  span,
  svg {
    vertical-align: middle;
  }
}

.name {
  font-size: 14px;
  font-weight: 600;
  margin-right: 8px;
}

.avatar {
  border-radius: 50%;
  height: 32px;
  width: 32px;
}
</style>
