<template>
  <div>
    <div>
      <div>
        <custom-modal name="new-link">
          <button
            class="button-icon modal-home-indicator"
            @click="$modal.hide('new-link')"
          ></button>
          <h5 class="title">New Link</h5>
          <form v-on:submit.prevent="submitCreatingLink">
            <fieldset>
              <label>Title</label>
              <input
                required
                type="text"
                placeholder="Title"
                v-model="creatingLink.title"
              />
            </fieldset>
            <fieldset>
              <label>URL</label>
              <input
                required
                type="text"
                placeholder="http[s]://your_url"
                v-model="creatingLink.link"
              />
            </fieldset>
            <div class="fieldset-col-2" v-if="isTimeVisible">
              <fieldset>
                <label>Start</label>
                <datetime
                  format="dd/MM/yyyy, hh:mm"
                  placeholder="Start time"
                  v-model="creatingLink.date_from"
                  type="datetime"
                ></datetime>
              </fieldset>
              <fieldset>
                <label>End</label>
                <datetime
                  format="dd/MM/yyyy, hh:mm"
                  placeholder="End time"
                  v-model="creatingLink.date_to"
                  type="datetime"
                ></datetime>
              </fieldset>
            </div>
            <fieldset class="no-border fieldset-buttons">
              <button
                type="submit"
                class="button-primary button-padding-12"
                onclick="ga('send', 'event', 'Link', 'Create', 'Account')"
              >
                Apply
              </button>
              <button
                type="button"
                class="button-secondary button-padding-12"
                @click="$modal.hide('new-link')"
              >
                Cancel
              </button>
            </fieldset>
          </form>
        </custom-modal>
        <section class="section">
          <div>
            <header class="heading section-heading">
              <h5 class="title">{{ page.name }}</h5>
              <button class="button-secondary">Add Link</button>
            </header>
            <hr class="section-divider" />
          </div>
          <div>
            <article v-for="link in links" :key="link.id">
              <Link
                :link="link"
                @delete="deleteLink"
                @edit="editLink"
                :editable="true"
              />
            </article>
          </div>
        </section>
      </div>
    </div>

    <alert-modal name="display-error-message">
      <h4 class="title">Error on complete the operation</h4>
      <button
        type="button"
        class="button-primary action-button"
        @click="$modal.hide('display-error-message')"
      >
        Close
      </button>
    </alert-modal>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { Datetime as datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import { UUIDv4 } from "./Common.js";

import Checkbox from "./Checkbox.vue";
import Link from "./Link.vue";

import {
  appendLink,
  enableAccount,
  getPage,
  removeLink,
  updateLinks,
} from "./Ajax.js";

export default {
  components: {
    Checkbox,
    datetime,
    draggable,
    Link,
  },
  computed: {
    me() {
      return this.$root.ssrData.profile;
    },
  },
  created() {
    this.loadPageLinks();
  },
  data() {
    return {
      creatingLink: {},
      links: [],
      page: {},
      isTimeVisible: false,
    };
  },
  methods: {
    loadPageLinks() {
      return getPage(this.$root.ssrData.pageID).then((page) => {
        this.page = page;
        const links = page.links || [];
        links.forEach((link) => {
          link.enabled = false;
        });
        this.links = links.slice().reverse();
      });
    },

    deleteLink(link) {
      removeLink(link).then(() => {
        this.links = this.links.filter((x) => x.id !== link.id);
        this.$emit("refresh");
      });
    },

    editLink(link) {
      const links = [...this.links];
      const index = links.findIndex((x) => x.id === link.id);
      links.splice(index, 1, link);

      const ll = links.slice().reverse();
      updateLinks(ll).then((links) => {
        this.links = links.slice().reverse();
        if (link.modalId) {
          this.$modal.hide(link.modalId);
        }
        this.$emit("refresh");
      });
    },

    openNewLinkModal() {
      this.creatingLink = Object.assign({}, this.creatingLink, {
        title: undefined,
        link: undefined,
        date_from: undefined,
        date_to: undefined,

        isChartVisible: false,
        isChartLoaded: false,
      });
      this.$modal.show("new-link");
    },

    reorderLinks() {
      ga("send", "event", "Link", "Reorder", "Account");
      this.saveLinks();
    },

    saveLinks() {
      const links = this.links.slice().reverse();
      updateLinks(links).then(() => {
        this.$emit("refresh");
      });
    },

    submitCreatingLink() {
      const link = {
        ...this.creatingLink,
        id: UUIDv4(),
        enabled: true,
      };
      link.date_from = link.date_from ? link.date_from : null;
      link.date_to = link.date_to ? link.date_to : null;
      appendLink(link).then((data) => {
        this.$modal.hide("new-link");
        if (data === undefined) {
          this.$modal.show("display-error-message");
          return;
        }

        this.links = [data, ...this.links];
        if (this.links.length === 1) {
          ga("send", "event", "Account", "Activate", "Automatically");
          enableAccount(true);
        }
        this.$emit("refresh");
      });
    },

    toggleTime() {
      this.isTimeVisible = !this.isTimeVisible;
    },
  },
};
</script>

<style lang="scss" scoped>
.flipped-y {
  transform: scale(1, -1);
}
</style>
