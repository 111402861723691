<template>
  <modal
    :adaptive="true"
    height="auto"
    :name="name"
    :scrollable="true"
    :width="width || 524"
  >
    <div class="modal-content" :id="name">
      <button class="button-icon close-button" @click="$modal.hide(name)">
        <svg width="24px" height="24px" viewBox="0 0 24 24">
          <g
            id="Artboard"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g
              id="Group"
              transform="translate(5.000000, 5.000000)"
              fill="#A5AAB2"
            >
              <path
                d="M14,8 L-5.70654635e-14,8 C-0.55228,8 -1,7.55228 -1,7 C-1,6.44772 -0.55228,6 -5.70654635e-14,6 L14,6 C14.5523,6 15,6.44772 15,7 C15,7.55228 14.5523,8 14,8 Z"
                id="Path"
                transform="translate(7.000000, 7.000000) rotate(-45.000000) translate(-7.000000, -7.000000) "
              ></path>
              <path
                d="M14,8 L-5.70654635e-14,8 C-0.55228,8 -1,7.5523 -1,7 C-1,6.4477 -0.55228,6 -5.70654635e-14,6 L14,6 C14.5523,6 15,6.4477 15,7 C15,7.5523 14.5523,8 14,8 Z"
                id="Path"
                transform="translate(7.000000, 7.000000) rotate(45.000000) translate(-7.000000, -7.000000) "
              ></path>
            </g>
          </g>
        </svg>
      </button>
      <div class="alert-modal-content">
        <slot></slot>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  props: ["action", "content", "name", "title", "width"],
};
</script>

<style>
.alert-modal-content {
  padding: 32px;
  text-align: center;
}

.close-button {
  position: absolute;
  right: 21px;
  top: 21px;
}

.action-button {
  height: 48px;
  margin-top: 56px;
  max-width: 280px;
  width: 100%;
}
</style>
