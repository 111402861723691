<template>
  <div>
    <div>
      <div>
        <section class="section">
          <div>
            <header class="heading section-heading">
              <h5 class="title">{{ smart_link.name }}</h5>
              <button
                class="button-primary"
                type="submit"
                v-on:click="deleteSmartLink()"
              >
                Delete Smart Link
              </button>
            </header>
            <hr class="section-divider" />
          </div>
          <div>
            <article v-for="link in smart_link.links" :key="link.id">
              <Link :link="link" :editable="false" />
            </article>
          </div>
        </section>
      </div>
    </div>

    <alert-modal name="display-error-message">
      <h4 class="title">Error on complete the operation</h4>
      <button
        type="button"
        class="button-primary action-button"
        @click="$modal.hide('display-error-message')"
      >
        Close
      </button>
    </alert-modal>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { Datetime as datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import { UUIDv4 } from "./Common.js";

import Checkbox from "./Checkbox.vue";
import Link from "./Link.vue";

import {
  appendLink,
  deleteSmartLink,
  enableAccount,
  getPage,
  removeLink,
  updateLinks,
} from "./Ajax.js";

export default {
  components: {
    Checkbox,
    datetime,
    draggable,
    Link,
  },
  computed: {
    me() {
      return this.$root.ssrData.profile;
    },
    smart_link() {
      return this.$root.ssrData.smart_link;
    },
  },
  created() {
    window.app = this;
  },
  data() {
    return {
      creatingLink: {},
      links: [],
      page: {},
      isTimeVisible: false,
    };
  },
  methods: {
    deleteSmartLink() {
      deleteSmartLink(this.$root.ssrData.smart_link.id).then(() => {
        window.open("/u/edit", "_self");
      });
    },

    toggleTime() {
      this.isTimeVisible = !this.isTimeVisible;
    },
  },
};
</script>

<style lang="scss" scoped>
.flipped-y {
  transform: scale(1, -1);
}
</style>
