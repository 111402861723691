<template>
  <div>
    <div class="preview-header">
      <span class="preview-header-link">
        <a target="_blank" :href="application_url + '/' + route"
          >{{ application_domain }}/{{ route }}</a
        >
      </span>
      <div class="preview-header-share">
        <button @click="copyLink()" class="button-outline">Copy Link</button>
        <Share v-slot:default="slotProps" :url="shareUrl">
          <button
            class="button-primary button-share"
            @click="slotProps.share()"
          >
            <img src="/static/images/share.svg" alt="Share" />
          </button>
        </Share>
      </div>
    </div>
    <div class="preview-body">
      <h5>Choose a theme:</h5>
      <div class="theme-selection">
        <article
          class="theme-preview"
          @click="selectTheme('professional')"
          v-bind:class="{ selected: theme === 'professional' }"
        >
          <img src="/static/preview-professional.png" />
        </article>
        <article
          class="theme-preview"
          @click="selectTheme('minimal')"
          v-bind:class="{ selected: theme === 'minimal' }"
        >
          <img src="/static/preview-minimal.png" />
        </article>
        <article
          class="theme-preview"
          @click="selectTheme('splash')"
          v-bind:class="{ selected: theme === 'splash' }"
        >
          <img src="/static/preview-splash.png" />
        </article>
        <article
          class="theme-preview"
          @click="selectTheme('fun')"
          v-bind:class="{ selected: theme === 'fun' }"
        >
          <img src="/static/preview-fun.png" />
        </article>
        <article
          class="theme-preview"
          @click="selectTheme('bandlab')"
          v-bind:class="{ selected: theme === 'bandlab' }"
        >
          <img src="/static/preview-bandlab.png" />
        </article>
        <article
          class="theme-preview"
          @click="selectTheme('mono')"
          v-bind:class="{ selected: theme === 'mono' }"
        >
          <img src="/static/preview-mono.png" />
        </article>
        <article
          class="theme-preview"
          @click="selectTheme('teisco')"
          v-bind:class="{ selected: theme === 'teisco' }"
        >
          <img src="/static/preview-teisco.png" />
        </article>
        <article
          class="theme-preview"
          @click="selectTheme('sweelee')"
          v-bind:class="{ selected: theme === 'sweelee' }"
        >
          <img src="/static/preview-sweelee.png" />
        </article>
      </div>
      <div class="iframe-container">
        <iframe
          ref="previewIframe"
          id="preview-iframe"
          frameborder="0"
          :src="'/' + route + '?preview'"
        >
        </iframe>
      </div>
    </div>
  </div>
</template>

<script>
import copy from "copy-text-to-clipboard";

import {
  getAccount,
  getAlbumLinks,
  getEventLinks,
  getPageLinks,
  setTheme,
  saveAlbumLinks,
  saveEventLinks,
  savePageLinks,
} from "./Ajax.js";

import Share from "./Share.vue";

export default {
  components: {
    Share,
  },
  computed: {
    application_domain() {
      return this.$root.ssrData.application_domain;
    },
    application_url() {
      return this.$root.ssrData.application_url;
    },
    route() {
      return this.$root.ssrData.path;
    },
    pageType() {
      const routeName = this.$router.currentRoute.name;
      if (routeName.startsWith("album")) {
        return "album";
      } else if (routeName.startsWith("event")) {
        return "event";
      } else if (routeName.startsWith("page")) {
        return "page";
      }
      return "account";
    },
    pageLink() {
      return this.$router.currentRoute.params.link;
    },
    shareUrl() {
      return this.application_url + "/" + this.route;
    },
  },
  data() {
    return {
      theme: null,
      pageInfo: {},
    };
  },
  created() {
    this.loadTheme();
  },
  destroyed() {
    window.removeEventListener("resize", this.position);
  },
  methods: {
    copyLink() {
      copy(this.application_url + "/" + this.route);
    },
    loadTheme() {
      const getInfo = () => {
        switch (this.pageType) {
          case "album":
            return getAlbumLinks(this.pageLink);
          case "event":
            return getEventLinks(this.pageLink);
          case "page":
            return getPageLinks(this.pageLink);
          default:
            return getAccount();
        }
      };

      getInfo().then(({ theme, ...info }) => {
        this.theme = theme;
        this.pageInfo = info;
      });
    },
    selectTheme(theme) {
      const setInfo = () => {
        const requestBody = {
          ...this.pageInfo,
          theme: theme,
        };
        switch (this.pageType) {
          case "page":
            return savePageLinks(this.pageLink, requestBody);
          case "album":
            return saveAlbumLinks(this.pageLink, requestBody);
          case "event":
            return saveEventLinks(this.pageLink, requestBody);
          default:
            return setTheme(theme);
        }
      };

      setInfo().then(() => {
        this.theme = theme;
        this.refreshPreview();
      });
    },
    refreshPreview() {
      this.$refs.previewIframe.contentWindow.location.reload();
    },
  },
  mounted() {
    const previewIframe = this.$refs.previewIframe;
    this.position = function () {
      const IPHONE_BODY_WIDTH = 378;
      const IPHONE_BODY_HEIGHT = 770;
      const IPHONE_WIDTH = 350;
      const IPHONE_HEIGHT = 742;

      const containerRect = previewIframe.parentElement.getBoundingClientRect();
      if (
        containerRect.width / containerRect.height <
        IPHONE_BODY_WIDTH / IPHONE_BODY_HEIGHT
      ) {
        const scale = containerRect.width / IPHONE_BODY_WIDTH;
        previewIframe.style.transform = `scale(${scale})`;
        previewIframe.style.width = `${IPHONE_WIDTH}px`;

        const height =
          (previewIframe.clientWidth * IPHONE_HEIGHT) / IPHONE_WIDTH;
        previewIframe.style.height = `${height}px`;
      } else {
        const scale = containerRect.height / IPHONE_BODY_HEIGHT;
        previewIframe.style.transform = `scale(${scale})`;
        previewIframe.style.height = `${IPHONE_HEIGHT}px`;

        const width =
          (previewIframe.clientHeight * IPHONE_WIDTH) / IPHONE_HEIGHT;
        previewIframe.style.width = `${width}px`;
      }
    };

    this.position();
    window.addEventListener("resize", this.position);
  },
};
</script>

<style lang="scss" scoped>
@import "colors.scss";

.preview-header {
  align-items: center;
  border-bottom: 1px solid $color-elements-300;
  display: flex;
  justify-content: space-between;
  margin: 0 16px;
  padding: 24px 0;
}

.preview-header-link {
  font-size: 16px;
  font-weight: 600;
  overflow: hidden;
  text-decoration: underline;
  text-overflow: ellipsis;
}

.preview-header-share {
  align-items: center;
  display: flex;
  flex: 0 0 152px;

  button {
    margin-left: 8px;
  }

  .button-share {
    padding: 8px;
  }
}

.preview-body {
  margin: 16px;

  h5 {
    margin-bottom: 20px;
  }
}

.theme-selection {
  display: flex;
  width: 100%;
  overflow-x: auto;
  padding: 4px;
}

.theme-preview {
  border-radius: 8px;
  box-shadow: 0 0 0 1px #92929d;
  cursor: pointer;
  flex-shrink: 0;
  height: 40px;
  margin-right: 16px;
  margin-bottom: 12px;
  width: 40px;

  &.selected {
    box-shadow: 0 0 0 2px #0062ff;
  }

  img {
    border-radius: 8px;
    height: 100%;
    width: 100%;
  }
}

.preview {
  background-color: #44444f;
  border-left: 1px solid #e2e2ea;
  display: flex;
  flex-direction: column;
}

.iframe-container {
  display: flex;
  height: 600px;
  justify-content: center;
  margin-top: 14px;
  position: relative;
  width: 100%;

  iframe {
    border: 10px solid $color-elements-900;
    border-radius: 50px;
    bottom: 0;
    position: absolute;
    top: 0;
    margin: auto;
  }
}
</style>
