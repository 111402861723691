var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"sidebar"},[_c('div',{staticClass:"top"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"my-pages"},[_c('div',{staticClass:"my-pages-header"},[_c('h5',[_vm._v("My Pages")]),_vm._v(" "),_c('div',{staticClass:"new-links"},[_c('a',{staticClass:"my-pages-add-affiliate",class:{ 'modal-hide': !_vm.isEditor },on:{"click":function($event){return _vm.openAffiliateLinkModal()}}},[_c('img',{attrs:{"src":"/static/images/plus.svg","alt":"","title":"add affiliate link"}})]),_vm._v(" "),_c('a',{staticClass:"my-pages-add-smart-link",class:{ 'modal-hide': !_vm.isEditor },on:{"click":function($event){return _vm.openSmartLinkModal()}}},[_c('img',{attrs:{"src":"/static/images/plus-2.svg","alt":"","title":"add smart link"}})])])]),_vm._v(" "),_c('ul',[_c('li',{staticClass:"my-page"},[_c('a',{staticClass:"my-page-link",class:{
                active:
                  _vm.currentRoute.startsWith("/u/") &&
                  _vm.currentRoute.indexOf('affiliate-link') === -1 &&
                  _vm.currentRoute.indexOf('smart-link') === -1,
              },attrs:{"href":"/"}},[_c('img',{staticClass:"my-page-icon",attrs:{"src":"/static/images/page.svg"}}),_vm._v(" "),_c('h5',[_vm._v(_vm._s(_vm.me.name))])])]),_vm._v(" "),_vm._l((_vm.pages),function(page){return _c('li',{key:page.id,staticClass:"my-page"},[_c('div',{staticClass:"my-page-link",class:{
                active: _vm.currentRoute.startsWith(("/u/pages/" + (page.uuid))),
              },on:{"click":function($event){return _vm.openPage(page.uuid)}}},[_c('img',{staticClass:"my-page-icon",attrs:{"src":"/static/images/page.svg"}}),_vm._v(" "),_c('h5',[_vm._v(_vm._s(page.name))])])])}),_vm._v(" "),_vm._l((_vm.affiliate_links),function(link){return _c('li',{key:link.code,staticClass:"my-page"},[_c('div',{staticClass:"my-page-link",class:{
                active: _vm.currentRoute.startsWith(
                  ("/u/affiliate-link/" + (link.code))
                ),
              },on:{"click":function($event){return _vm.openAffiliateLink(link.code)}}},[_c('img',{staticClass:"my-page-icon",attrs:{"src":"/static/images/page.svg"}}),_vm._v(" "),_c('h5',[_vm._v(_vm._s(link.title))])])])}),_vm._v(" "),_vm._l((_vm.smart_links),function(link){return _c('li',{key:link.id,staticClass:"my-page"},[_c('div',{staticClass:"my-page-link",class:{
                active: _vm.currentRoute.startsWith(("/u/smart-link/" + (link.id))),
              },on:{"click":function($event){return _vm.openSmartLinkPage(link.id)}}},[_c('img',{staticClass:"my-page-icon",attrs:{"src":"/static/images/page.svg"}}),_vm._v(" "),_c('h5',[_vm._v(_vm._s(link.name))])])])})],2)])]),_vm._v(" "),_c('div',{staticClass:"bottom"},[_c('hr',{staticClass:"sidebar-divider"}),_vm._v(" "),(false)?_c('div',{staticClass:"sidebar-action"},[_c('img',{attrs:{"src":"/static/images/faq.svg"}}),_vm._v(" "),_c('a',{attrs:{"href":""}},[_vm._v("FAQ")])]):_vm._e(),_vm._v(" "),_vm._m(1),_vm._v(" "),_c('div',{staticClass:"sidebar-action"},[_c('img',{staticClass:"avatar",attrs:{"src":_vm.me.picture.extraSmall}}),_vm._v(" "),_c('a',{attrs:{"target":"_blank","href":'https://bandlab.com/' + _vm.me.username}},[_vm._v(_vm._s(_vm.me.name))])]),_vm._v(" "),_vm._m(2)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"logo"},[_c('a',{attrs:{"href":"/"}},[_c('img',{staticStyle:{"width":"150px"},attrs:{"src":"/static/images/logo-bandlab-links.svg"}})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sidebar-action"},[_c('img',{attrs:{"src":"/static/images/report.svg"}}),_vm._v(" "),_c('a',{attrs:{"href":"javascript:$crisp.push(['do', 'chat:open']);"}},[_vm._v("Report a Bug")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sidebar-action"},[_c('a',{attrs:{"href":"/auth/logout"}},[_vm._v("Logout")])])}]

export { render, staticRenderFns }