<template>
  <svg
    class="loader loader-no-content"
    width="26px"
    height="26px"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
  >
    <g class="rect-container" transform="translate(25 25)">
      <rect
        x="-20"
        y="-20"
        width="40"
        height="40"
        fill="#92929D"
        opacity="0.9"
        class="cube"
      ></rect>
    </g>
    <g class="rect-container" transform="translate(75 25)">
      <rect
        x="-20"
        y="-20"
        width="40"
        height="40"
        fill="#92929D"
        opacity="0.8"
        class="cube"
      ></rect>
    </g>
    <g class="rect-container" transform="translate(75 75)">
      <rect
        x="-20"
        y="-20"
        width="40"
        height="40"
        fill="#92929D"
        opacity="0.7"
        class="cube"
      ></rect>
    </g>
    <g class="rect-container" transform="translate(25 75)">
      <rect
        x="-20"
        y="-20"
        width="40"
        height="40"
        fill="#92929D"
        opacity="0.6"
        class="cube"
      ></rect>
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.loader {
  display: block;
  transform: translate3d(0, 0, 0); // GPU acceleration
}

@keyframes cube-scale {
  0% {
    transform: scale(1.2);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.2);
  }
}

.loader-no-content {
  @extend .loader;
  height: 26px;
  margin: auto;
  width: 26px;

  .cube {
    animation: cube-scale 1s ease-in-out infinite;
    transition: all 0.25s ease-in-out;
  }

  @for $i from 1 through 4 {
    .rect-container:nth-child(#{$i}) {
      rect {
        animation-delay: calc((#{$i}s) / 4);
      }
    }
  }
}
</style>
