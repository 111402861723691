export function UUIDv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

export function FormatDatetime(date) {
  const o = new Date(date);
  return o.toLocaleDateString("default", {
    hour: "numeric",
    minute: "numeric",
  });
}

export function DatetimeArray(from, to) {
  let dates = [];
  let dt = from;
  while (dt <= to) {
    dates.push(new Date(dt));
    dt.setDate(dt.getDate() + 1);
  }
  return dates;
}
