<template>
  <div>
    <custom-modal name="share">
      <button
        class="button-icon modal-home-indicator"
        @click="$modal.hide('share')"
      ></button>
      <h5 class="title">Share</h5>
      <div class="share-buttons">
        <facebook :url="url" scale="2"></facebook>
        <twitter :url="url" :title="webSiteTitle" scale="2"></twitter>
        <linkedin :url="url" scale="2"></linkedin>
        <telegram :url="url" scale="2"></telegram>
        <br />
        <whats-app :url="url" :title="webSiteTitle" scale="2"></whats-app>
        <pinterest :url="url" scale="2"></pinterest>
        <reddit :url="url" scale="2" :title="webSiteTitle"></reddit>
        <email :url="url" :subject="webSiteTitle" scale="2"></email>
      </div>
      <fieldset class="no-border fieldset-buttons">
        <button
          type="button"
          class="button-secondary button-padding-12"
          @click="$modal.hide('share')"
        >
          Cancel
        </button>
      </fieldset>
    </custom-modal>

    <slot v-bind:share="share"></slot>
  </div>
</template>
<script>
import {
  Facebook,
  Twitter,
  Linkedin,
  Pinterest,
  Reddit,
  Telegram,
  WhatsApp,
  Email,
  Google,
} from "vue-socialmedia-share";

import { getAccount } from "./Ajax.js";

export default {
  components: {
    Facebook,
    Twitter,
    Linkedin,
    Pinterest,
    Reddit,
    Telegram,
    WhatsApp,
    Email,
    Google,
  },
  data() {
    return {
      isMobile: window.innerWidth <= 768,
      webSiteTitle: "",
    };
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth <= 768;
    },
    share() {
      if (navigator.share) {
        navigator
          .share({
            title: this.webSiteTitle,
            url: this.url,
          })
          .catch(console.error);
      } else {
        this.$modal.show("share");
      }
    },
  },
  mounted() {
    window.addEventListener("resize", this.onResize);

    getAccount().then(({ title }) => {
      this.webSiteTitle = title;
    });
  },
  props: ["url"],
};
</script>

<style scoped>
.share-buttons {
  text-align: center;
}
</style>
