<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 23C17.5228 23 22 18.5228 22 13C22 7.47715 17.5228 3 12 3C6.47715 3 2 7.47715 2 13C2 18.5228 6.47715 23 12 23ZM12 21C7.58172 21 4 17.4183 4 13C4 8.58172 7.58172 5 12 5C16.4183 5 20 8.58172 20 13C20 17.4183 16.4183 21 12 21ZM11 13V8.00004C11 7.44775 11.4477 7.00004 12 7.00004C12.5523 7.00004 13 7.44775 13 8.00004V13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13Z"
      fill="#171725"
    />
    <path
      d="M14.8571 -4.99559e-08L9.14286 -2.99735e-07C8.51167 -3.27325e-07 8 0.447715 8 1C8 1.55228 8.51167 2 9.14286 2L14.8571 2C15.4883 2 16 1.55228 16 1C16 0.447715 15.4883 -2.2366e-08 14.8571 -4.99559e-08Z"
      fill="#171725"
    />
  </svg>
</template>
<script>
export default {};
</script>
