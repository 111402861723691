<template>
  <main>
    <section class="section">
      <div class="card desktop-card">
        <form onsubmit="return false">
          <div class="bandlab-settings">
            <img :src="me.picture.extraSmall" />
            <div class="bandlab-settings-toggle">
              <button
                class="button-icon"
                title="Toggle this checkbox to publish/unpublish you account page"
                v-on:click="toggleAccount"
                v-bind:class="{ active: !disabled }"
              >
                <Checkbox />
              </button>
              <h5>Public Page</h5>
            </div>
            <fieldset>
              <label>Page Title</label>
              <input type="text" v-model="title" :placeholder="me.name" />
            </fieldset>
          </div>
        </form>
        <div class="hint">
          <p>
            To update your avatar please visit
            <a href="https://www.bandlab.com/settings/profile" target="_blank"
              >profile settings on BandLab</a
            >
            and click
            <a href="javascript: window.location.reload()">here</a> to apply
            changes.
          </p>
        </div>

        <h5 class="title hide-for-small-below">Theme</h5>
        <div class="theme-selection">
          <article
            class="theme-preview"
            @click="selectTheme('professional')"
            v-bind:class="{ selected: theme === 'professional' }"
          >
            <img src="/static/preview-professional.png" />
          </article>
          <article
            class="theme-preview"
            @click="selectTheme('minimal')"
            v-bind:class="{ selected: theme === 'minimal' }"
          >
            <img src="/static/preview-minimal.png" />
          </article>
          <article
            class="theme-preview"
            @click="selectTheme('splash')"
            v-bind:class="{ selected: theme === 'splash' }"
          >
            <img src="/static/preview-splash.png" />
          </article>
          <article
            class="theme-preview"
            @click="selectTheme('fun')"
            v-bind:class="{ selected: theme === 'fun' }"
          >
            <img src="/static/preview-fun.png" />
          </article>
          <article
            class="theme-preview"
            @click="selectTheme('bandlab')"
            v-bind:class="{ selected: theme === 'bandlab' }"
          >
            <img src="/static/preview-bandlab.png" />
          </article>
          <article
            class="theme-preview"
            @click="selectTheme('mono')"
            v-bind:class="{ selected: theme === 'mono' }"
          >
            <img src="/static/preview-mono.png" />
          </article>
          <article
            class="theme-preview"
            @click="selectTheme('teisco')"
            v-bind:class="{ selected: theme === 'teisco' }"
          >
            <img src="/static/preview-teisco.png" />
          </article>
          <article
            class="theme-preview"
            @click="selectTheme('sweelee')"
            v-bind:class="{ selected: theme === 'sweelee' }"
          >
            <img src="/static/preview-sweelee.png" />
          </article>
        </div>
        <div class="share-button-toggle">
          <button
            class="button-icon"
            v-on:click="toggleShareButton()"
            v-bind:class="{ active: displayShareButton }"
          >
            <Checkbox />
          </button>
          <h5 class="title">Share button</h5>
        </div>
        <h5 class="title">Website</h5>
        <form onsubmit="return false">
          <fieldset>
            <label>Direct url under your logo</label>
            <input
              type="text"
              placeholder="http://example.com"
              v-model="sns.url.link"
            />
            <button
              class="button-icon"
              v-on:click="toggleLink(sns.url, 'Url')"
              v-bind:class="{ active: sns.url.enabled }"
            >
              <Checkbox />
            </button>
          </fieldset>
        </form>
        <div class="social-settings-toggle">
          <button
            class="button-icon"
            v-on:click="toggleSocial()"
            v-bind:class="{ active: socialEnabled }"
          >
            <Checkbox />
          </button>
          <h5 class="title">Social accounts</h5>
        </div>
        <form onsubmit="return false">
          <fieldset>
            <label>Discord Server</label>
            <input
              type="text"
              placeholder="https://discord.gg/INVITE"
              v-model="sns.discord.link"
            />
            <button
              class="button-icon"
              v-if="sns.discord.link"
              v-on:click="toggleLink(sns.discord, 'Discord')"
              v-bind:class="{ active: sns.discord.enabled }"
            >
              <Checkbox />
            </button>
          </fieldset>
          <fieldset>
            <label>Facebook Page</label>
            <input
              type="text"
              placeholder="https://facebook.com/username"
              v-model="sns.facebook.link"
            />
            <button
              class="button-icon"
              v-if="sns.facebook.link"
              v-on:click="toggleLink(sns.facebook, 'Facebook')"
              v-bind:class="{ active: sns.facebook.enabled }"
            >
              <Checkbox />
            </button>
          </fieldset>
          <fieldset>
            <label>Instagram Username</label>
            <input
              type="text"
              placeholder="my_instagram"
              v-model="sns.instagram.link"
            />
            <button
              class="button-icon"
              v-if="sns.instagram.link"
              v-on:click="toggleLink(sns.instagram, 'Instagram')"
              v-bind:class="{ active: sns.instagram.enabled }"
            >
              <Checkbox />
            </button>
          </fieldset>
          <fieldset>
            <label>Twitter Handle</label>
            <input
              type="text"
              placeholder="my_twitter"
              v-model="sns.twitter.link"
            />
            <button
              class="button-icon"
              v-if="sns.twitter.link"
              v-on:click="toggleLink(sns.twitter, 'Twitter')"
              v-bind:class="{ active: sns.twitter.enabled }"
            >
              <Checkbox />
            </button>
          </fieldset>
          <fieldset>
            <label>YouTube channel</label>
            <input
              type="text"
              placeholder="https://youtube.com/c/channel"
              v-model="sns.youtube.link"
            />
            <button
              class="button-icon"
              v-if="sns.youtube.link"
              v-on:click="toggleLink(sns.youtube, 'YouTube')"
              v-bind:class="{ active: sns.youtube.enabled }"
            >
              <Checkbox />
            </button>
          </fieldset>
          <fieldset>
            <label>TikTok Account</label>
            <input
              type="text"
              placeholder="my_tiktok"
              v-model="sns.tiktok.link"
            />
            <button
              class="button-icon"
              v-if="sns.tiktok.link"
              v-on:click="toggleLink(sns.tiktok, 'TikTok')"
              v-bind:class="{ active: sns.tiktok.enabled }"
            >
              <Checkbox />
            </button>
          </fieldset>
          <fieldset>
            <label>SoundCloud Page</label>
            <input
              type="text"
              placeholder="https://soundcloud.com/username"
              v-model="sns.soundcloud.link"
            />
            <button
              class="button-icon"
              v-if="sns.soundcloud.link"
              v-on:click="toggleLink(sns.soundcloud, 'SoundCloud')"
              v-bind:class="{ active: sns.soundcloud.enabled }"
            >
              <Checkbox />
            </button>
          </fieldset>
          <fieldset>
            <label>BandLab Username</label>
            <input
              type="text"
              disabled="disabled"
              placeholder="my_bandlab"
              v-model="sns.bandlab_account.link"
            />
            <button
              class="button-icon"
              v-on:click="toggleLink(sns.bandlab_account, 'BandLab')"
              v-bind:class="{ active: sns.bandlab_account.enabled }"
            >
              <Checkbox />
            </button>
          </fieldset>
          <fieldset>
            <label>Email</label>
            <input
              type="text"
              placeholder="email@address.com"
              v-model="sns.email.link"
            />
            <button
              class="button-icon"
              v-if="sns.email.link"
              v-on:click="toggleLink(sns.email, 'Email')"
              v-bind:class="{ active: sns.email.enabled }"
            >
              <Checkbox />
            </button>
          </fieldset>
          <fieldset class="fieldset-right no-border">
            <button
              type="submit"
              class="button-primary"
              v-on:click="saveAccount"
            >
              Save
            </button>
          </fieldset>
        </form>
      </div>
    </section>
  </main>
</template>

<script>
import { getAccount, enableAccount, setTheme, updateAccount } from "./Ajax.js";
import Checkbox from "./Checkbox.vue";

export default {
  components: {
    Checkbox,
  },
  computed: {
    me() {
      return this.$root.ssrData.profile;
    },
  },
  data() {
    return {
      disabled: null,
      displayShareButton: null,
      theme: null,
      title: null,
      sns: {
        bandlab_account: {},
        discord: {},
        email: {},
        facebook: {},
        tiktok: {},
        twitter: {},
        instagram: {},
        soundcloud: {},
        url: {},
        youtube: {},
      },
      socialEnabled: false,
    };
  },
  created() {
    this.loadAccount();
  },
  methods: {
    isSocialEnabled() {
      return (
        this.sns.bandlab_account.enabled ||
        this.sns.discord.enabled ||
        this.sns.facebook.enabled ||
        this.sns.tiktok.enabled ||
        this.sns.twitter.enabled ||
        this.sns.instagram.enabled ||
        this.sns.soundcloud.enabled ||
        this.sns.email.enabled ||
        this.sns.youtube.enabled
      );
    },

    loadAccount() {
      return getAccount().then(
        ({ disabled, display_share_button, theme, title, ...sns }) => {
          this.disabled = disabled;
          this.displayShareButton = display_share_button;
          this.sns = sns;
          this.theme = theme;
          this.title = title;
          this.socialEnabled = this.isSocialEnabled();
        }
      );
    },

    saveAccount() {
      const account = {
        disabled: this.disabled,
        display_share_button: this.displayShareButton,
        theme: this.theme,
        title: this.title,

        ...this.sns,
      };
      updateAccount(account).then(
        ({ disabled, display_share_button, theme, title, ...sns }) => {
          this.disabled = disabled;
          this.displayShareButton = display_share_button;
          this.sns = sns;
          this.theme = theme;
          this.title = title;
          this.socialEnabled = this.isSocialEnabled();

          this.$emit("refresh");
        }
      );
    },

    selectTheme(theme) {
      setTheme(theme).then(() => {
        this.theme = theme;
        this.$emit("refresh");
      });
    },

    submitAccount() {
      this.saveAccount();
    },

    toggleAccount() {
      if (this.disabled) {
        ga("send", "event", "Account", "Activate", "Manually");
      } else {
        ga("send", "event", "Account", "Deactivate", "Manually");
      }
      enableAccount(this.disabled).then(() => {
        this.disabled = !this.disabled;
        this.$emit("refresh");
      });
    },

    toggleLink(link, kind) {
      link.enabled = !link.enabled;
      if (link.enabled) {
        ga("send", "event", "Social", "Activate", "Account", kind);
      } else {
        ga("send", "event", "Social", "Deactivate", "Account", kind);
      }
      this.saveAccount();
    },

    toggleShareButton() {
      this.displayShareButton = !this.displayShareButton;
      if (this.displayShareButton) {
        ga("send", "event", "ShareButton", "Activate", "Account");
      } else {
        ga("send", "event", "ShareButton", "Deactivate", "Account");
      }
      this.saveAccount();
    },

    toggleSocial() {
      if (this.socialEnabled) {
        this.sns.bandlab_account.enabled = false;
        this.sns.discord.enabled = false;
        this.sns.facebook.enabled = false;
        this.sns.tiktok.enabled = false;
        this.sns.twitter.enabled = false;
        this.sns.instagram.enabled = false;
        this.sns.soundcloud.enabled = false;
        this.sns.email.enabled = false;
        this.sns.youtube.enabled = false;
        ga("send", "event", "Social", "Deactivate", "Account", "All");
      } else {
        this.sns.bandlab_account.enabled = !!this.sns.bandlab_account.link;
        this.sns.discord.enabled = !!this.sns.discord.link;
        this.sns.facebook.enabled = !!this.sns.facebook.link;
        this.sns.tiktok.enabled = !!this.sns.tiktok.link;
        this.sns.twitter.enabled = !!this.sns.twitter.link;
        this.sns.instagram.enabled = !!this.sns.instagram.link;
        this.sns.soundcloud.enabled = !!this.sns.soundcloud.link;
        this.sns.email.enabled = !!this.sns.email.link;
        this.sns.youtube.enabled = !!this.sns.youtube.link;
        ga("send", "event", "Social", "Activate", "Account", "All");
      }
      this.saveAccount();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "colors.scss";

form:not(:last-child),
.theme-selection {
  margin-bottom: 24px;
}

.theme-selection {
  display: flex;
  flex-wrap: wrap;

  @media screen and (max-width: 768px) {
    display: none;
  }
}

.theme-preview {
  border-radius: 8px;
  box-shadow: 0 0 0 1px #92929d;
  cursor: pointer;
  flex-shrink: 0;
  height: 40px;
  margin-right: 16px;
  margin-bottom: 12px;
  width: 40px;

  &.selected {
    box-shadow: 0 0 0 2px #0062ff;
  }

  img {
    border-radius: 8px;
    height: 100%;
    width: 100%;
  }
}

.share-button-toggle {
  align-items: center;
  display: flex;

  .button-icon {
    margin-right: 6px;
  }
}

.social-settings-toggle {
  align-items: center;
  display: flex;

  .button-icon {
    margin-right: 6px;
  }
}
</style>
