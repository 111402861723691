<template>
  <div>
    <custom-modal :name="modalId.edit">
      <button
        class="button-icon modal-home-indicator"
        @click="$modal.hide(modalId.edit)"
      ></button>
      <h5 class="title">Edit Link</h5>
      <form v-on:submit.prevent="submitEditedLink">
        <fieldset>
          <label>Title</label>
          <input
            required
            type="text"
            placeholder="Title"
            v-model="editingLink.title"
          />
        </fieldset>
        <fieldset>
          <label>URL</label>
          <input
            required
            type="text"
            placeholder="http[s]://your_url"
            v-model="editingLink.link"
          />
        </fieldset>
        <div class="modal-time">
          <button
            class="button-icon"
            title="Toggle this checkbox to set a schedule for your link"
            type="button"
            v-on:click="toggleTime()"
            v-bind:class="{
              active: editingLink.date_from || editingLink.date_to,
            }"
          >
            <Checkbox />
          </button>
          <h5>Duration time</h5>
        </div>
        <div
          class="fieldset-col-2"
          v-if="editingLink.date_from || editingLink.date_to"
        >
          <fieldset>
            <label>Start</label>
            <datetime
              format="dd/MM/yyyy, hh:mm"
              placeholder="Start time"
              v-model="editingLink.date_from"
              type="datetime"
            ></datetime>
          </fieldset>
          <fieldset>
            <label>End</label>
            <datetime
              format="dd/MM/yyyy, hh:mm"
              placeholder="End time"
              v-model="editingLink.date_to"
              type="datetime"
            ></datetime>
          </fieldset>
        </div>
        <fieldset class="no-border fieldset-buttons">
          <button
            type="submit"
            class="button-primary button-padding-12"
            onclick="ga('send', 'event', 'Link', 'Edit', 'Account');"
          >
            Apply
          </button>
          <button
            type="button"
            class="button-secondary button-padding-12"
            @click="$modal.hide(modalId.edit)"
          >
            Cancel
          </button>
        </fieldset>
      </form>
    </custom-modal>
    <custom-modal :name="modalId.action">
      <div class="modal-action-header">
        <div>
          <h5 class="link-title">{{ link.title }}</h5>
          <div class="link-link">
            {{ link.link }}
          </div>
        </div>
        <button class="button-icon" @click="$modal.hide(modalId.action)">
          <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M15 13.5858L20.9497 7.63604C21.3403 7.24551 21.9734 7.24551 22.3639 7.63604C22.7545 8.02656 22.7545 8.65973 22.3639 9.05025L16.4142 15L22.3639 20.9497C22.7545 21.3403 22.7545 21.9734 22.3639 22.364C21.9734 22.7545 21.3403 22.7545 20.9497 22.364L15 16.4142L9.05024 22.364C8.65971 22.7545 8.02655 22.7545 7.63602 22.364C7.2455 21.9734 7.2455 21.3403 7.63602 20.9497L13.5858 15L7.63602 9.05025C7.2455 8.65973 7.2455 8.02656 7.63602 7.63604C8.02655 7.24551 8.65971 7.24552 9.05024 7.63604L15 13.5858Z"
              fill="#171725"
            />
          </svg>
        </button>
      </div>
      <div class="modal-action-buttons">
        <button class="button-secondary button-padding-12" disabled>
          <span>Stats</span>
          <svg
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <g
              id="Component/icon/ic_Leaderboard"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <path
                d="M15,22 L9,22 L3,22 C2.44771525,22 2,21.5522847 2,21 L2,8.5 C2,7.3954305 2.8954305,6.5 4,6.5 L8,6.5 L8,4 C8,2.8954305 8.8954305,2 10,2 L14,2 C15.1045695,2 16,2.8954305 16,4 L16,11 L20,11 C21.1045695,11 22,11.8954305 22,13 L22,21 C22,21.5522847 21.5522847,22 21,22 L15,22 Z M14,20 L14,13 L14,4 L10,4 L10,8.5 L10,20 L14,20 Z M16,20 L20,20 L20,13 L16,13 L16,20 Z M8,8.5 L4,8.5 L4,20 L8,20 L8,8.5 Z"
                id="Shape"
                fill="#92929D"
              ></path>
            </g>
          </svg>
        </button>
        <button
          class="button-secondary button-padding-12"
          v-on:click="openEditLinkModal()"
        >
          <span>Edit</span>
          <svg
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <g
              id="Component/icon/ic_Edit"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <path
                d="M17.8609615,7.59610325 L18.9757174,6.48134736 L16.5186526,4.02428259 L15.4038968,5.13903847 L17.8609615,7.59610325 Z M16.4541876,9.00287717 L13.9971228,6.5458124 L4.80565292,15.7372823 L4.24013801,18.759862 L7.26271769,18.1943471 L16.4541876,9.00287717 L16.4541876,9.00287717 Z M20.4419052,5.13398729 C21.1860316,5.87811371 21.1860316,7.08458102 20.4419052,7.82870744 L8.45173906,19.8188736 C8.30989153,19.9607211 8.12847146,20.0563677 7.93129027,20.0932596 L3.17815536,20.9825558 C2.49083247,21.1111517 1.88884828,20.5091675 2.01744417,19.8218446 L2.90674038,15.0687097 C2.94363235,14.8715285 3.03927891,14.6901085 3.18112643,14.5482609 L15.1712926,2.55809482 C15.915419,1.81396839 17.1218863,1.81396839 17.8660127,2.55809482 L20.4419052,5.13398729 Z M20.009901,21 L11.990099,21 C10.669967,21 10.669967,19 11.990099,19 L20.009901,19 C21.330033,19 21.330033,21 20.009901,21 Z"
                id="Shape"
                fill="#92929D"
              ></path>
            </g>
          </svg>
        </button>
        <button
          class="button-secondary button-padding-12"
          v-on:click="deleteLink()"
        >
          <span>Delete</span>
          <svg
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <g
              id="Component/icon/ic_Trash"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <path
                d="M7.6633106,4.7 L8.33538653,2.68377223 C8.47150057,2.27543009 8.85363942,2 9.28406982,2 L14.6840698,2 C15.1145002,2 15.4966391,2.27543009 15.6327531,2.68377223 L16.304829,4.7 L20.9840289,4.7 C21.5363137,4.7 21.9840289,5.14771525 21.9840289,5.7 C21.9840289,6.25228475 21.5363137,6.7 20.9840289,6.7 L20.1269749,6.7 L19.3930828,19.1761661 C19.299807,20.761854 17.9866886,22 16.3982597,22 L7.56987998,22 C5.98145106,22 4.66833262,20.761854 4.57505687,19.1761661 L3.84116474,6.7 L3,6.7 C2.44771525,6.7 2,6.25228475 2,5.7 C2,5.14771525 2.44771525,4.7 3,4.7 L7.6633106,4.7 Z M9.77149571,4.7 L14.1966439,4.7 L13.9633106,4 L10.004829,4 L9.77149571,4.7 L9.77149571,4.7 Z M18.1235177,6.7 L5.84462196,6.7 L6.57160561,19.058722 C6.60269753,19.5872847 7.04040368,20 7.56987998,20 L16.3982597,20 C16.927736,20 17.3654421,19.5872847 17.396534,19.058722 L18.1235177,6.7 Z M13.6860172,9.23762171 C13.7204678,8.6864125 14.1952389,8.26749685 14.7464481,8.30194742 C15.2976573,8.336398 15.716573,8.81116907 15.6821224,9.36237829 L15.2321224,16.5623783 C15.1976718,17.1135875 14.7229008,17.5325032 14.1716915,17.4980526 C13.6204823,17.463602 13.2015667,16.9888309 13.2360172,16.4376217 L13.6860172,9.23762171 Z M10.7321224,16.4376217 C10.766573,16.9888309 10.3476573,17.463602 9.79644811,17.4980526 C9.24523889,17.5325032 8.77046782,17.1135875 8.73601725,16.5623783 L8.28601725,9.36237829 C8.25156667,8.81116907 8.67048232,8.336398 9.22169154,8.30194742 C9.77290076,8.26749685 10.2476718,8.6864125 10.2821224,9.23762171 L10.7321224,16.4376217 Z"
                id="Shape"
                fill="#92929D"
              ></path>
            </g>
          </svg>
        </button>
      </div>
    </custom-modal>
    <custom-modal :name="modalId.stats">
      <button
        class="button-icon modal-home-indicator"
        @click="$modal.hide(modalId.stats)"
      ></button>
      <h5 class="title">Link Stats</h5>
      <div class="card-content link-chart" :ref="'link-chart-' + modalId.stats">
        <loader v-if="!isChartLoaded"></loader>
      </div>
    </custom-modal>
    <div class="card card-link" v-bind:class="{ inactive: !link.enabled }">
      <div class="link-article">
        <div class="link-handle">
          <img src="/static/images/slider.svg" />
        </div>
        <div class="link-body">
          <h5 class="link-title">{{ link.title }}</h5>
          <div class="link-date" v-if="link.date_from || link.date_to">
            {{ durationDateFormat(link.date_from, link.date_to) }}
          </div>
          <div class="link-link">
            <a :href="link.link" target="_blank" rel="noopener">{{
              link.link
            }}</a>
          </div>
        </div>
        <div class="link-actions" v-if="editable">
          <button
            class="button-icon"
            v-on:click="toggleLink()"
            v-bind:class="{ active: link.enabled }"
          >
            <Checkbox v-if="!link.date_from && !link.date_to" />
            <Clock v-if="link.date_from || link.date_to" />
          </button>

          <div class="vertical-rule hide-for-small-below"></div>

          <button
            class="button-icon hide-for-small-below"
            v-on:click="openLinkStatsChart()"
            v-bind:class="{ active: this.isChartVisible }"
          >
            <svg
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <g
                id="Component/icon/ic_Leaderboard"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <path
                  d="M15,22 L9,22 L3,22 C2.44771525,22 2,21.5522847 2,21 L2,8.5 C2,7.3954305 2.8954305,6.5 4,6.5 L8,6.5 L8,4 C8,2.8954305 8.8954305,2 10,2 L14,2 C15.1045695,2 16,2.8954305 16,4 L16,11 L20,11 C21.1045695,11 22,11.8954305 22,13 L22,21 C22,21.5522847 21.5522847,22 21,22 L15,22 Z M14,20 L14,13 L14,4 L10,4 L10,8.5 L10,20 L14,20 Z M16,20 L20,20 L20,13 L16,13 L16,20 Z M8,8.5 L4,8.5 L4,20 L8,20 L8,8.5 Z"
                  id="Shape"
                  fill="#92929D"
                ></path>
              </g>
            </svg>
          </button>
          <button
            class="button-icon hide-for-small-below"
            v-on:click="openEditLinkModal()"
          >
            <svg
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <g
                id="Component/icon/ic_Edit"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <path
                  d="M17.8609615,7.59610325 L18.9757174,6.48134736 L16.5186526,4.02428259 L15.4038968,5.13903847 L17.8609615,7.59610325 Z M16.4541876,9.00287717 L13.9971228,6.5458124 L4.80565292,15.7372823 L4.24013801,18.759862 L7.26271769,18.1943471 L16.4541876,9.00287717 L16.4541876,9.00287717 Z M20.4419052,5.13398729 C21.1860316,5.87811371 21.1860316,7.08458102 20.4419052,7.82870744 L8.45173906,19.8188736 C8.30989153,19.9607211 8.12847146,20.0563677 7.93129027,20.0932596 L3.17815536,20.9825558 C2.49083247,21.1111517 1.88884828,20.5091675 2.01744417,19.8218446 L2.90674038,15.0687097 C2.94363235,14.8715285 3.03927891,14.6901085 3.18112643,14.5482609 L15.1712926,2.55809482 C15.915419,1.81396839 17.1218863,1.81396839 17.8660127,2.55809482 L20.4419052,5.13398729 Z M20.009901,21 L11.990099,21 C10.669967,21 10.669967,19 11.990099,19 L20.009901,19 C21.330033,19 21.330033,21 20.009901,21 Z"
                  id="Shape"
                  fill="#92929D"
                ></path>
              </g>
            </svg>
          </button>
          <button
            class="button-icon hide-for-small-below"
            v-on:click="deleteLink()"
          >
            <svg
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <g
                id="Component/icon/ic_Trash"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <path
                  d="M7.6633106,4.7 L8.33538653,2.68377223 C8.47150057,2.27543009 8.85363942,2 9.28406982,2 L14.6840698,2 C15.1145002,2 15.4966391,2.27543009 15.6327531,2.68377223 L16.304829,4.7 L20.9840289,4.7 C21.5363137,4.7 21.9840289,5.14771525 21.9840289,5.7 C21.9840289,6.25228475 21.5363137,6.7 20.9840289,6.7 L20.1269749,6.7 L19.3930828,19.1761661 C19.299807,20.761854 17.9866886,22 16.3982597,22 L7.56987998,22 C5.98145106,22 4.66833262,20.761854 4.57505687,19.1761661 L3.84116474,6.7 L3,6.7 C2.44771525,6.7 2,6.25228475 2,5.7 C2,5.14771525 2.44771525,4.7 3,4.7 L7.6633106,4.7 Z M9.77149571,4.7 L14.1966439,4.7 L13.9633106,4 L10.004829,4 L9.77149571,4.7 L9.77149571,4.7 Z M18.1235177,6.7 L5.84462196,6.7 L6.57160561,19.058722 C6.60269753,19.5872847 7.04040368,20 7.56987998,20 L16.3982597,20 C16.927736,20 17.3654421,19.5872847 17.396534,19.058722 L18.1235177,6.7 Z M13.6860172,9.23762171 C13.7204678,8.6864125 14.1952389,8.26749685 14.7464481,8.30194742 C15.2976573,8.336398 15.716573,8.81116907 15.6821224,9.36237829 L15.2321224,16.5623783 C15.1976718,17.1135875 14.7229008,17.5325032 14.1716915,17.4980526 C13.6204823,17.463602 13.2015667,16.9888309 13.2360172,16.4376217 L13.6860172,9.23762171 Z M10.7321224,16.4376217 C10.766573,16.9888309 10.3476573,17.463602 9.79644811,17.4980526 C9.24523889,17.5325032 8.77046782,17.1135875 8.73601725,16.5623783 L8.28601725,9.36237829 C8.25156667,8.81116907 8.67048232,8.336398 9.22169154,8.30194742 C9.77290076,8.26749685 10.2476718,8.6864125 10.2821224,9.23762171 L10.7321224,16.4376217 Z"
                  id="Shape"
                  fill="#92929D"
                ></path>
              </g>
            </svg>
          </button>
          <button
            class="button-icon show-for-small-below"
            @click="$modal.show(modalId.action)"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 7C13.1046 7 14 6.10457 14 5C14 3.89543 13.1046 3 12 3C10.8954 3 10 3.89543 10 5C10 6.10457 10.8954 7 12 7ZM12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14ZM14 19C14 20.1046 13.1046 21 12 21C10.8954 21 10 20.1046 10 19C10 17.8954 10.8954 17 12 17C13.1046 17 14 17.8954 14 19Z"
                fill="#B5B5BE"
              />
            </svg>
          </button>
        </div>
      </div>
      <div class="card-content link-chart" v-show="this.isChartVisible">
        <nav class="buttons-container">
          <button
            class="button-secondary"
            @click="selectPeriod(7)"
            v-bind:class="{ active: this.period === 7 }"
          >
            Week
          </button>
          <button
            class="button-secondary"
            @click="selectPeriod(28)"
            v-bind:class="{ active: this.period === 28 }"
          >
            Month
          </button>
        </nav>
        <h5 class="title">{{ formattedStartTime }} - {{ formattedEndTime }}</h5>
        <div class="subhead-3">{{ viewsCount }} page views</div>
        <div :ref="'link-chart-' + link.id" v-show="isChartLoaded"></div>
        <loader v-if="!isChartLoaded"></loader>
      </div>
    </div>
  </div>
</template>

<script>
import { Datetime as datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import { FormatDatetime, DatetimeArray } from "./Common.js";

import Checkbox from "./Checkbox.vue";
import Clock from "./Clock.vue";
import loader from "./Loader.vue";

import { getLinkStats, loadGoogleCharts } from "./Ajax.js";

export default {
  components: {
    datetime,
    Checkbox,
    Clock,
    loader,
  },
  data() {
    return {
      editingLink: {},
      isChartLoaded: false,
      isChartVisible: false,

      isMobile: window.innerWidth <= 768,

      period: 7,
      startTime: null,
      endTime: null,
      viewsCount: "-",
    };
  },
  computed: {
    modalId() {
      return {
        action: `action-${this.link.id}`,
        edit: `edit-${this.link.id}`,
        stats: `stats-${this.link.id}`,
      };
    },
    formattedStartTime() {
      if (!this.startTime) {
        return;
      }

      return new Intl.DateTimeFormat("en-US", {
        day: "numeric",
        month: "short",
        year: "numeric",
      }).format(this.startTime);
    },
    formattedEndTime() {
      if (!this.endTime) {
        return;
      }

      return new Intl.DateTimeFormat("en-US", {
        day: "numeric",
        month: "short",
        year: "numeric",
      }).format(this.endTime);
    },
  },
  created() {
    this.selectPeriod(7);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  props: ["link", "editable"],
  methods: {
    durationDateFormat(date_from, date_to) {
      if (date_from && date_to)
        return (
          "From " +
          this.formatDatetime(date_from) +
          " to " +
          this.formatDatetime(date_to)
        );
      if (date_from) return "From " + this.formatDatetime(date_from);
      if (date_to) return "To " + this.formatDatetime(date_to);
    },
    selectPeriod(period) {
      this.period = period;
      this.startTime = new Date(Date.now() - period * 24 * 60 * 60 * 1000);
      this.endTime = new Date();
      this.loadLinkStatsChart();
    },
    deleteLink() {
      ga("send", "event", "Link", "Delete", "Account");
      this.$emit("delete", this.link);
    },

    formatDatetime(date) {
      return FormatDatetime(date);
    },

    onResize() {
      this.isMobile = window.innerWidth <= 768;
    },

    openEditLinkModal() {
      this.editingLink = { ...this.link };
      this.$modal.hide(this.modalId.action);
      this.$modal.show(this.modalId.edit);
    },

    openLinkStatsChart() {
      if (this.isChartVisible) {
        this.isChartVisible = false;
        return;
      }
      this.isChartVisible = true;
      ga("send", "event", "Link", "ViewStats", "Account");

      this.startTime = new Date(Date.now() - this.period * 24 * 60 * 60 * 1000);
      this.endTime = new Date();
      this.loadLinkStatsChart();
    },

    loadLinkStatsChart(chartId = "link-chart-" + this.link.id) {
      this.isChartLoaded = false;
      return Promise.all([
        getLinkStats(this.link, this.period),
        loadGoogleCharts(),
      ]).then(async ([stats]) => {
        this.viewsCount = stats.total_events.count_page_views_total;

        const data = new google.visualization.DataTable();
        data.addColumn("string", "Day");
        data.addColumn("number", "Clicks");
        data.addColumn({
          type: "string",
          role: "tooltip",
          p: { html: true },
        });

        let i = 0;
        DatetimeArray(this.startTime, this.endTime).forEach(function (date) {
          let dt = new Intl.DateTimeFormat("en-US", {
            day: "numeric",
            month: "short",
            year: "numeric",
          }).format(new Date(date));
          let val = 0;
          const k = date.toLocaleDateString("sv-AX") + "T00:00:00";
          if (
            stats.events[i] !== undefined &&
            stats.events[i].created_aggr === k
          ) {
            val = stats.events[i].count_clicks_total;
            i += 1;
          }
          data.addRow([dt, val, getTooltip(dt, val, "clicks")]);
        });

        const ref = this.$refs[chartId];
        const chart = new google.visualization.ColumnChart(ref);
        this.isChartLoaded = true;
        await this.$nextTick();
        chart.draw(data, {
          chartArea: { width: "90%", height: "80%" },
          height: 300,
          width: "100%",
          colors: ["#0062FF", "#FF7A00"],
          hAxis: {
            showTextEvery: { 7: 2, 28: 7 }[this.period],
          },
          vAxis: {
            baselineColor: "#F2F2F3",
            gridlines: { color: "#F2F2F3" },
            minorGridlines: { color: "#F2F2F3" },
          },
          axes: {
            x: {
              0: { side: "bottom", label: "" },
            },
          },
          bar: {
            groupWidth: "80%",
          },
          legend: {
            position: "none",
          },
          tooltip: { isHtml: true },
        });
      });
    },

    submitEditedLink() {
      this.editingLink.date_from = this.editingLink.date_from
        ? this.editingLink.date_from
        : null;
      this.editingLink.date_to = this.editingLink.date_to
        ? this.editingLink.date_to
        : null;
      if (
        this.editingLink.date_from != null ||
        this.editingLink.date_to != null
      ) {
        ga("send", "event", "Link", "Schedule", "Account");
      }

      this.$emit("edit", {
        ...this.editingLink,
        modalId: this.modalId.edit,
      });
    },

    toggleLink() {
      this.link.enabled = !this.link.enabled;
      if (this.link.enabled) {
        ga("send", "event", "Link", "Activate", "Account");
      } else {
        ga("send", "event", "Link", "Deactivate", "Account");
      }
      this.$emit("edit", this.link);
    },

    toggleTime() {
      if (this.editingLink.date_from || this.editingLink.date_to) {
        this.editingLink = {
          ...this.editingLink,
          date_from: null,
          date_to: null,
        };
      } else {
        const dateTo = new Date();
        dateTo.setFullYear(dateTo.getFullYear() + 1);
        this.editingLink = {
          ...this.editingLink,
          date_from: new Date().toISOString(),
          date_to: dateTo.toISOString(),
        };
      }
    },
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
  },
};

function getTooltip(date, value, type) {
  return (
    "<div class='google-chart-tooltip'><h5>" +
    Math.round(value) +
    " " +
    type +
    "</h5><div class='subhead-3'>" +
    date +
    "</div></div>"
  );
}
</script>

<style lang="scss" scoped>
@import "./colors.scss";

.loader {
  height: 300px;
}

.modal-action-header {
  align-items: center;
  border-bottom: 1px solid $color-elements-300;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 12px 24px;

  > div {
    width: calc(100% - 50px);
  }

  .button-icon {
    color: $color-elements-900;
  }
}

.modal-action-buttons {
  display: flex;
  flex-direction: column;
  margin-top: 16px;

  button {
    justify-content: space-between;
    margin-bottom: 16px;

    svg {
      color: $color-elements-500;
    }
  }
}

.link-article {
  display: flex;
}

.link-handle {
  align-items: center;
  cursor: move;
  display: flex;
  justify-content: center;
  margin-right: 8px;

  svg {
    transform: rotate(90deg);
  }
}

.link-body {
  flex: 1;
  overflow: hidden;
}

.link-title,
.link-link {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.link-title {
  margin-bottom: 6px;
}

.link-actions {
  align-items: center;
  display: flex;
  justify-content: center;

  .vertical-rule {
    height: 24px;
    margin: 0 10px;
  }
}

.subhead-3 {
  color: $color-elements-700;
}

.buttons-container {
  display: flex;
  margin-bottom: 10px;
  margin-top: 10px;
}

.button-secondary {
  display: flex;
  flex: 1;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  padding: 12px 16px;

  &.active {
    color: $color-accent-default;
  }
}
</style>
