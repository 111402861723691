<template>
  <div>
    <section class="section">
      <div>
        <header class="heading section-heading">
          <h5 class="title">
            Affiliate link:
            <a
              target="_blank"
              :href="application_url + '/' + route + '/' + affiliateLink.code"
              >{{ application_domain }}/{{ route }}/{{ affiliateLink.code }}</a
            >
          </h5>
        </header>
        <form>
          <fieldset>
            <label>Title</label>
            <input
              required
              type="text"
              placeholder="Link Title"
              v-model="affiliateLink.title"
            />
          </fieldset>
          <fieldset>
            <label>URL</label>
            <input
              required
              type="text"
              placeholder="https://bandlab.com"
              v-model="affiliateLink.url"
            />
          </fieldset>
          <fieldset class="fieldset-right no-border">
            <button
              type="button"
              class="button-primary"
              v-on:click="saveAffiliateLink"
            >
              Save
            </button>
            <button
              type="button"
              class="button-primary"
              v-on:click="removeAffiliateLink"
            >
              Delete
            </button>
          </fieldset>
        </form>
        <hr class="section-divider" />
      </div>
      <div class="card">
        <header class="heading">
          <h5 class="title">Clicks</h5>
          <img src="/static/images/stats.svg" alt="Clicks" />
        </header>
        <nav class="buttons-container">
          <button
            class="button-secondary"
            @click="selectPeriod(7)"
            v-bind:class="{ active: this.period === 7 }"
          >
            Week
          </button>
          <button
            class="button-secondary"
            @click="selectPeriod(28)"
            v-bind:class="{ active: this.period === 28 }"
          >
            Month
          </button>
        </nav>
        <h5 class="title">{{ formattedStartTime }} - {{ formattedEndTime }}</h5>
        <div class="card-content">
          <loader v-if="!isMainChartLoaded"></loader>
          <div
            class="main-chart"
            ref="mainChart"
            v-show="isMainChartLoaded"
          ></div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {
  deleteAffiliateLink,
  getAffiliateLinkStats,
  loadGoogleCharts,
  updateAffiliateLink,
} from "./Ajax.js";
import loader from "./Loader.vue";
import { DatetimeArray } from "./Common";

export default {
  computed: {
    affiliateLink() {
      return this.$root.ssrData.affiliate_link;
    },
    application_domain() {
      return this.$root.ssrData.application_domain;
    },
    application_url() {
      return this.$root.ssrData.application_url;
    },
    route() {
      return this.$root.ssrData.path;
    },
    formattedStartTime() {
      if (!this.startTime) {
        return;
      }

      return new Intl.DateTimeFormat("en-US", {
        day: "numeric",
        month: "short",
        year: "numeric",
      }).format(this.startTime);
    },
    formattedEndTime() {
      if (!this.endTime) {
        return;
      }

      return new Intl.DateTimeFormat("en-US", {
        day: "numeric",
        month: "short",
        year: "numeric",
      }).format(this.endTime);
    },
  },
  components: {
    loader,
  },
  created() {
    this.selectPeriod(7);
  },
  destroyed() {
    window.removeEventListener("resize", this.draw);
  },
  data() {
    return {
      isMainChartLoaded: false,

      period: 0,
      startTime: null,
      endTime: null,
    };
  },
  methods: {
    removeAffiliateLink() {
      const link = this.$root.ssrData.affiliate_link;
      deleteAffiliateLink(link.code).then(() => {
        window.open("/u/edit", "_self");
      });
    },
    saveAffiliateLink() {
      const link = this.$root.ssrData.affiliate_link;
      updateAffiliateLink(link.code, link.title, encodeURI(link.url)).then(
        () => {
          this.$emit("refresh");
        }
      );
    },
    selectPeriod(period) {
      this.period = period;
      this.startTime = new Date(Date.now() - period * 24 * 60 * 60 * 1000);
      this.endTime = new Date();
      this.loadStats();
    },
    loadStats() {
      this.isMainChartLoaded = false;
      return Promise.all([
        getAffiliateLinkStats(this.affiliateLink.code, this.period),
        loadGoogleCharts(),
      ]).then(async ([stats]) => {
        const chartData = new google.visualization.DataTable();
        chartData.addColumn("string", "Day");
        chartData.addColumn("number", "Clicks");
        chartData.addColumn({
          type: "string",
          role: "tooltip",
          p: { html: true },
        });

        let i = 0;
        DatetimeArray(this.startTime, this.endTime).forEach(function (date) {
          let dt = new Intl.DateTimeFormat("en-US", {
            day: "numeric",
            month: "short",
            year: "numeric",
          }).format(new Date(date));
          let val = 0;
          const k = date.toLocaleDateString("sv-AX") + "T00:00:00";
          if (
            stats.events[i] !== undefined &&
            stats.events[i].created_aggr === k
          ) {
            val = stats.events[i].count_clicks_total;
            i += 1;
          }
          chartData.addRow([dt, val, getTooltip(dt, val, "clicks")]);
        });

        const chart = new google.visualization.ColumnChart(
          this.$refs.mainChart
        );

        this.draw = function () {
          chart.draw(chartData, {
            chartArea: { width: "90%", height: "80%" },
            height: 300,
            width: "100%",
            colors: ["#0062FF", "#FF7A00"],
            hAxis: {
              showTextEvery: { 7: 2, 28: 7 }[this.period],
            },
            vAxis: {
              baselineColor: "#F2F2F3",
              gridlines: { color: "#F2F2F3" },
              minorGridlines: { color: "#F2F2F3" },
            },
            axes: {
              x: {
                0: { side: "bottom", label: "" },
              },
            },
            bar: {
              groupWidth: "80%",
            },
            legend: {
              position: "none",
            },
            tooltip: { isHtml: true },
          });
        };

        this.isMainChartLoaded = true;
        window.addEventListener("resize", this.draw);
        await this.$nextTick();
        this.draw();
      });
    },
  },
};

function getTooltip(date, value, type) {
  return (
    "<div class='google-chart-tooltip'><h5>" +
    Math.round(value) +
    " " +
    type +
    "</h5><div class='subhead-3'>" +
    date +
    "</div></div>"
  );
}
</script>

<style lang="scss" scoped>
@import "colors.scss";

.loader {
  height: 300px;
}

.subhead-3 {
  color: $color-elements-700;
}

.buttons-container {
  display: flex;
  margin-bottom: 10px;
  margin-top: 10px;
}

.button-secondary {
  display: flex;
  flex: 1;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  padding: 12px 16px;

  &:not(:first-child) {
    margin-left: 8px;
  }

  &:not(:last-child) {
    margin-right: 8px;
  }

  &.active {
    color: $color-accent-default;
  }
}

.main-chart {
  margin-bottom: 24px;
}

.footnote {
  margin: 24px 0;
}
</style>
