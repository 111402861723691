import cookies from "browser-cookies";
import ky from "ky";

const ajax = ky.extend({
  hooks: {
    beforeRequest: [
      function (options) {
        const accessToken = cookies.get("accessToken");
        if (accessToken) {
          options.headers.set("authorization", "Bearer " + accessToken);
        }
      },
    ],
  },
});

const ajaxError = (error) => {
  if (error.response.status === 403) {
    window.location.href = "/auth/login";
  }
};

export function appendLink(link) {
  const json = {
    ...link,
  };
  const response = ajax.post("/v3/link", { json });
  return response.json().catch(ajaxError);
}

export function deleteAffiliateLink(code) {
  return ajax.delete(`/v3/affiliate-link/${code}`).catch(ajaxError);
}

export function deleteSmartLink(id) {
  return ajax.delete(`/v3/smart-link/${id}`).catch(ajaxError);
}

export function enableAccount(enabled) {
  return ajax.put("/v3/account/enable?value=" + enabled).catch(ajaxError);
}

export function getAffiliateLinks() {
  return ajax.get("/v3/affiliate-links").json().catch(ajaxError);
}

export function getAccount() {
  const response = ajax.get("/v3/account");
  return response.json().catch(ajaxError);
}

export function getAccountStats(period = 7) {
  return ajax.get(`/v3/stats?period=${period}`).json().catch(ajaxError);
}

export function getAffiliateLinkStats(code, period = 7) {
  return ajax
    .get(`/v3/stats?id=affiliate/${code}&period=${period}`)
    .json()
    .catch(ajaxError);
}

export function getLinks() {
  const response = ajax.get("/v3/links");
  return response.json().catch(ajaxError);
}

export function getLinkStats({ id, period = 7 }) {
  return ajax
    .get(`/v3/stats?id=${id}&period=${period}`)
    .json()
    .catch(ajaxError);
}

export function getPage(pageID) {
  return ajax
    .get("/v3/page/" + pageID)
    .json()
    .catch(ajaxError);
}

export function getPages() {
  return ajax.get("/v3/pages").json().catch(ajaxError);
}

export function getSmartLinks() {
  return ajax.get("/v3/smart-links").json().catch(ajaxError);
}

export function loadGoogleCharts() {
  return new Promise((resolve) => {
    google.charts.load("current", {
      callback: resolve,
      packages: ["bar", "corechart", "table"],
    });
  });
}

export function postAffiliateLink(code, title, url) {
  return ajax
    .post(`/v3/affiliate-link/${code}?title=${title}&url=${url}`)
    .catch(ajaxError);
}

export function postSmartLink(name, link) {
  return ajax
    .post(`/v3/smart-link?name=${name}&link=${link}`)
    .json()
    .catch(ajaxError);
}

export function removeLink(link) {
  const json = {
    ...link,
  };
  return ajax.delete("/v3/link", { json }).catch(ajaxError);
}

export function setTheme(theme) {
  return ajax.put("/v3/account/theme?value=" + theme).catch(ajaxError);
}

export function trackClick(tracker) {
  return ajax.get("/v3/trace_click?p=" + tracker);
}

export function trackPageView(tracker) {
  return ajax.get("/v3/trace_page_view?p=" + tracker);
}

export function trackView(tracker) {
  return ajax.get("/v3/trace_view?p=" + tracker);
}

export function updateAccount(account) {
  const json = {
    ...account,
  };
  const response = ajax.put("/v3/account", { json });
  return response.json().catch(ajaxError);
}

export function updateAffiliateLink(code, title, url) {
  return ajax
    .put(`/v3/affiliate-link/${code}?title=${title}&url=${url}`)
    .catch(ajaxError);
}

export function updateLinks(links) {
  const json = [...links];
  const response = ajax.put("/v3/links", { json });
  return response.json().catch(ajaxError);
}
