<template>
  <div>
    <div class="sidebar">
      <div class="top">
        <div class="logo">
          <a href="/">
            <img
              src="/static/images/logo-bandlab-links.svg"
              style="width: 150px;"
            />
          </a>
        </div>
        <div class="my-pages">
          <div class="my-pages-header">
            <h5>My Pages</h5>
            <div class="new-links">
              <a
                v-on:click="openAffiliateLinkModal()"
                class="my-pages-add-affiliate"
                :class="{ 'modal-hide': !isEditor }"
              >
                <img
                  src="/static/images/plus.svg"
                  alt=""
                  title="add affiliate link"
                />
              </a>
              <a
                v-on:click="openSmartLinkModal()"
                class="my-pages-add-smart-link"
                :class="{ 'modal-hide': !isEditor }"
              >
                <img
                  src="/static/images/plus-2.svg"
                  alt=""
                  title="add smart link"
                />
              </a>
            </div>
          </div>

          <ul>
            <li class="my-page">
              <a
                href="/"
                class="my-page-link"
                v-bind:class="{
                  active:
                    currentRoute.startsWith(`/u/`) &&
                    currentRoute.indexOf('affiliate-link') === -1 &&
                    currentRoute.indexOf('smart-link') === -1,
                }"
              >
                <img class="my-page-icon" src="/static/images/page.svg" />
                <h5>{{ me.name }}</h5>
              </a>
            </li>

            <!-- pages -->

            <li v-for="page in pages" v-bind:key="page.id" class="my-page">
              <div
                class="my-page-link"
                v-bind:class="{
                  active: currentRoute.startsWith(`/u/pages/${page.uuid}`),
                }"
                @click="openPage(page.uuid)"
              >
                <img class="my-page-icon" src="/static/images/page.svg" />
                <h5>{{ page.name }}</h5>
              </div>
            </li>

            <!-- affiliate links -->

            <li
              v-for="link in affiliate_links"
              v-bind:key="link.code"
              class="my-page"
            >
              <div
                class="my-page-link"
                v-bind:class="{
                  active: currentRoute.startsWith(
                    `/u/affiliate-link/${link.code}`
                  ),
                }"
                @click="openAffiliateLink(link.code)"
              >
                <img class="my-page-icon" src="/static/images/page.svg" />
                <h5>{{ link.title }}</h5>
              </div>
            </li>

            <!-- smart links -->

            <li
              v-for="link in smart_links"
              v-bind:key="link.id"
              class="my-page"
            >
              <div
                class="my-page-link"
                v-bind:class="{
                  active: currentRoute.startsWith(`/u/smart-link/${link.id}`),
                }"
                @click="openSmartLinkPage(link.id)"
              >
                <img class="my-page-icon" src="/static/images/page.svg" />
                <h5>{{ link.name }}</h5>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="bottom">
        <hr class="sidebar-divider" />
        <div class="sidebar-action" v-if="false">
          <img src="/static/images/faq.svg" />
          <a href="">FAQ</a>
        </div>
        <div class="sidebar-action">
          <img src="/static/images/report.svg" />
          <a href="javascript:$crisp.push(['do', 'chat:open']);"
            >Report a Bug</a
          >
        </div>
        <div class="sidebar-action">
          <img class="avatar" :src="me.picture.extraSmall" />
          <a target="_blank" :href="'https://bandlab.com/' + me.username">{{
            me.name
          }}</a>
        </div>
        <div class="sidebar-action">
          <a href="/auth/logout">Logout</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAffiliateLinks, getPages, getSmartLinks } from "./Ajax";
export default {
  computed: {
    application_url() {
      return this.$root.ssrData.application_url;
    },
    currentRoute() {
      return this.$router.currentRoute.path;
    },
    me() {
      return this.$root.ssrData.profile;
    },
  },
  props: {
    affiliate_links: {
      type: Array,
      default: () => [],
    },
    pages: {
      type: Array,
      default: () => [],
    },
    smart_links: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isMobile: window.innerWidth <= 768,
      isEditor: false,
    };
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth <= 768;
    },
    openAffiliateLink(code) {
      window.open(`/u/affiliate-link/${code}`, "_self");
    },
    openSmartLinkPage(id) {
      window.open(`/u/smart-link/${id}`, "_self");
    },
    openPage(pageID) {
      window.open(`/u/pages/${pageID}`, "_blank");
    },
    openAffiliateLinkModal() {
      this.$modal.show("new-affiliate-link");
    },
    openSmartLinkModal() {
      this.$modal.show("new-smart-link");
    },
  },
  mounted() {
    window.sidebar = this;
    getAffiliateLinks().then((links) => {
      this.affiliate_links = links;
      this.$root.ssrData.affiliate_links = links;
    });
    getSmartLinks().then((links) => {
      this.smart_links = links;
      this.$root.ssrData.smart_links = links;
    });
    getPages().then((pages) => {
      this.pages = pages;
      this.$root.ssrData.pages = pages;
    });
    window.addEventListener("resize", this.onResize);
    this.isEditor = this.$root.ssrData.role === "editor";
  },
};
</script>

<style lang="scss" scoped>
@import "colors.scss";

$sidebar-gutter-h: 24px;
$sidebar-gutter-v: 18px;
$sidebar-gutter-icon: 16px;

$sidebar-icon-size: 20px;
$sidebar-item-height: 56px;
$highlight-color: $color-accent-default;

.sidebar {
  border-right: 1px solid $color-elements-300;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.logo {
  align-items: center;
  display: flex;
  margin: $sidebar-gutter-v $sidebar-gutter-h 42px;

  a {
    display: flex;
  }

  img {
    margin-right: 10px;
    width: 29px;
  }

  @media screen and (max-width: 1200px) {
    display: none;
  }
}

.sidebar-divider {
  background-color: $color-elements-300;
  border: 0;
  height: 1px;
  margin: $sidebar-gutter-v $sidebar-gutter-h;
}

.modal-hide {
  display: none;
}

.my-pages {
  margin-top: 16px;

  ul {
    padding: 0;
  }
}

.my-pages-header {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 12px $sidebar-gutter-h;
}

.my-pages-add-affiliate,
.my-pages-add-smart-link {
  border-radius: 4px;
  cursor: pointer;
  padding: 7px;

  &:hover {
    background-color: $color-elements-100;
  }

  img {
    width: 18px;
  }
}

.my-page {
  list-style: none;
  align-items: center;
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  min-height: $sidebar-item-height;
  padding: 10px $sidebar-gutter-h;
  position: relative;
  width: 100%;

  .my-page-del {
    display: none;
  }

  &:hover {
    background-color: $color-elements-100;

    .my-page-del {
      display: block;
    }
  }

  .active h5 {
    color: $highlight-color;
  }

  .my-page-icon {
    height: $sidebar-icon-size;
    margin-right: $sidebar-gutter-icon;
  }

  .my-page-link {
    align-items: center;
    display: flex;

    &.active:after {
      background: $highlight-color;
      border-bottom-right-radius: 100px;
      border-top-right-radius: 100px;
      bottom: 12px;
      content: "";
      width: 3px;
      left: 0;
      position: absolute;
      top: 12px;
    }
  }
}

.sidebar-action {
  align-items: center;
  display: flex;
  font-weight: 600;
  height: $sidebar-item-height;
  padding: 10px $sidebar-gutter-h;

  &:hover {
    background-color: $color-elements-100;
  }

  img {
    margin-right: $sidebar-gutter-icon;
  }
}

.bottom {
  margin-top: 32px;
}

.avatar {
  border-radius: 50%;
  height: 24px;
  margin-right: 10px;
  width: 24px;
}
</style>
