<template>
  <modal
    :adaptive="true"
    height="auto"
    :name="name"
    @opened="scrollListen"
    :pivotY="isBottomSheet ? 1 : 0.5"
    :scrollable="true"
    v-bind:class="{ 'bottom-sheet': isBottomSheet }"
    :width="width || 600"
    :transition="isBottomSheet ? 'slide' : 'none'"
  >
    <div class="modal-content" :id="name">
      <slot></slot>
    </div>
  </modal>
</template>

<script>
export default {
  computed: {
    isBottomSheet() {
      return this.isMobile && this.isAdaptive;
    },
  },
  data() {
    return {
      isAdaptive: this.adaptive === undefined ? true : this.adaptive,
      isMobile: window.innerWidth <= 768,
    };
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth <= 768;
    },
    scrollListen() {
      let startY = null;
      const element = document.getElementById(this.name);
      element.addEventListener(
        "touchstart",
        function (event) {
          if (event.touches.length === 1) {
            startY = event.touches[0].clientY;
          }
        },
        false
      );
      element.addEventListener(
        "touchend",
        (event) => {
          if (startY && event.changedTouches.length === 1) {
            if (event.changedTouches[0].clientY - startY > 100) {
              this.$modal.hide(this.name);
            }
          }
          startY = null;
        },
        false
      );
    },
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
  },
  props: ["adaptive", "name", "width"],
};
</script>

<style>
.slide-enter-active,
.slide-leave-active {
  transition: all 0.5s;
}

.slide-enter,
.slide-leave-to {
  transform: translateY(100px);
}
</style>
