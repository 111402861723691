<template>
  <div v-bind:class="{ 'active-sidebar': isSidebarActive }">
    <custom-modal name="new-affiliate-link">
      <button
        class="button-icon modal-home-indicator"
        @click="$modal.hide('new-affiliate-link')"
      ></button>
      <h4 class="modal-title">Create Affiliate Link</h4>
      <h5 class="modal-title">Enter an new link</h5>
      <form v-on:submit.prevent="submitCreatingLink">
        <fieldset>
          <label>Title</label>
          <input
            required
            type="text"
            placeholder="Link Title"
            v-model="affiliateTitle"
          />
        </fieldset>
        <fieldset>
          <label>URL</label>
          <input
            required
            type="text"
            placeholder="https://bandlab.com"
            v-model="affiliateUrl"
          />
        </fieldset>
        <fieldset>
          <label>Referral code</label>
          <input
            required
            type="text"
            placeholder="abc"
            v-model="affiliateCode"
          />
        </fieldset>
      </form>
      <fieldset class="no-border fieldset-buttons">
        <button
          type="button"
          class="button-primary button-padding-12"
          @click="createAffiliateLink()"
        >
          Create
        </button>
        <button
          type="button"
          class="button-secondary button-padding-12"
          @click="$modal.hide('new-affiliate-link')"
        >
          Cancel
        </button>
      </fieldset>
    </custom-modal>
    <custom-modal name="new-smart-link">
      <button
        class="button-icon modal-home-indicator"
        @click="$modal.hide('new-smart-link')"
      ></button>
      <h4 class="modal-title">Create New Smart Link</h4>
      <h5 class="modal-title">Enter a Spotify/Soundcloud track link</h5>
      <form v-on:submit.prevent="submitCreatingLink">
        <fieldset>
          <label>Smart Link Title</label>
          <input
            required
            type="text"
            placeholder="Song Title"
            v-model="title"
          />
        </fieldset>
        <fieldset>
          <label>Track Link</label>
          <input
            required
            type="text"
            placeholder="https://open.spotify.com/track/60nZcImufyMA1MKQY3dcCH"
            v-model="url"
          />
        </fieldset>
      </form>
      <fieldset class="no-border fieldset-buttons">
        <button
          type="button"
          class="button-primary button-padding-12"
          @click="createSmartLink()"
        >
          Create Smart Link
        </button>
        <button
          type="button"
          class="button-secondary button-padding-12"
          @click="$modal.hide('new-smart-link')"
        >
          Cancel
        </button>
      </fieldset>
    </custom-modal>

    <main class="main-splitted">
      <SideBar class="side" />
      <div
        class="sidebar-mobile-overlay"
        v-on:click="toggleSidebar(false)"
      ></div>
      <div class="center">
        <div class="mobile-topbar">
          <button
            class="button-icon"
            v-on:click="toggleSidebar(true)"
            v-show="!isSidebarActive"
          >
            <img alt="Open sidebar" src="/static/images/burger.svg" />
          </button>
          <button
            class="button-icon"
            v-on:click="toggleSidebar(false)"
            v-show="isSidebarActive"
          >
            <svg
              title="close"
              width="28"
              height="28"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15 13.5858L20.9497 7.63604C21.3403 7.24551 21.9734 7.24551 22.3639 7.63604C22.7545 8.02656 22.7545 8.65973 22.3639 9.05025L16.4142 15L22.3639 20.9497C22.7545 21.3403 22.7545 21.9734 22.3639 22.364C21.9734 22.7545 21.3403 22.7545 20.9497 22.364L15 16.4142L9.05024 22.364C8.65971 22.7545 8.02655 22.7545 7.63602 22.364C7.2455 21.9734 7.2455 21.3403 7.63602 20.9497L13.5858 15L7.63602 9.05025C7.2455 8.65973 7.2455 8.02656 7.63602 7.63604C8.02655 7.24551 8.65971 7.24552 9.05024 7.63604L15 13.5858Z"
                fill="white"
              />
            </svg>
          </button>
          <div class="logo">
            <img
              src="/static/images/logo-bandlab-links-white.svg"
              style="width: 130px;"
            />
          </div>
        </div>
        <TopBar />
        <div class="dashboard">
          <router-view @refresh="refreshPreview()"></router-view>
        </div>
      </div>
      <div class="preview">
        <div class="my-link">
          <div class="my-link-header">Preview</div>
          <div class="my-link-link">
            <a target="_blank" :href="this.pageUrl">
              {{ pageUrl }}
            </a>
          </div>
          <div class="my-link-action">
            <button class="button-outline" @click="copyLink()">
              Copy Link
            </button>
            <Share v-slot:default="slotProps" :url="pageUrl">
              <button class="button-outline" @click="slotProps.share()">
                Share
              </button>
            </Share>
          </div>
        </div>
        <div class="iframe-container">
          <iframe
            ref="previewIframe"
            id="preview-iframe"
            frameborder="0"
            :src="previewUrl"
          >
          </iframe>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import Share from "./Share.vue";
import SideBar from "./SideBar.vue";
import TopBar from "./TopBar.vue";
import copy from "copy-text-to-clipboard";
import { postAffiliateLink, postSmartLink } from "./Ajax";

export default {
  components: {
    Share,
    SideBar,
    TopBar,
  },
  computed: {
    application_domain() {
      return this.$root.ssrData.application_domain;
    },
    application_url() {
      return this.$root.ssrData.application_url;
    },
    me() {
      return this.$root.ssrData.profile;
    },
    route() {
      return this.$root.ssrData.path;
    },
    pageUrl() {
      const userRoute = `${this.application_url}/${this.route}`;
      if (this.$root.ssrData.smart_link) {
        return userRoute + "/smart-link/" + this.$root.ssrData.smart_link.id;
      } else {
        return userRoute;
      }
    },
    previewUrl() {
      return this.pageUrl + "?preview";
    },
  },
  data() {
    return {
      code: "",
      title: "",
      url: "",

      isSidebarActive: false,
      pageID: this.$root.ssrData.pageID,
    };
  },
  destroyed() {
    window.removeEventListener("resize", this.position);
  },
  mounted() {
    const previewIframe = this.$refs.previewIframe;
    this.position = function() {
      const IPHONE_BODY_WIDTH = 378;
      const IPHONE_BODY_HEIGHT = 770;
      const IPHONE_WIDTH = 350;
      const IPHONE_HEIGHT = 742;

      const containerRect = previewIframe.parentElement.getBoundingClientRect();
      if (
        containerRect.width / containerRect.height <
        IPHONE_BODY_WIDTH / IPHONE_BODY_HEIGHT
      ) {
        const scale = containerRect.width / IPHONE_BODY_WIDTH;
        previewIframe.style.transform = `scale(${scale})`;
        previewIframe.style.width = `${IPHONE_WIDTH}px`;

        const height =
          (previewIframe.clientWidth * IPHONE_HEIGHT) / IPHONE_WIDTH;
        previewIframe.style.height = `${height}px`;
      } else {
        const scale = containerRect.height / IPHONE_BODY_HEIGHT;
        previewIframe.style.transform = `scale(${scale})`;
        previewIframe.style.height = `${IPHONE_HEIGHT}px`;

        const width =
          (previewIframe.clientHeight * IPHONE_WIDTH) / IPHONE_HEIGHT;
        previewIframe.style.width = `${width}px`;
      }
    };

    this.position();
    window.addEventListener("resize", this.position);
    window.admin = this;
  },
  methods: {
    copyLink() {
      copy(this.pageUrl);
    },
    refreshPreview() {
      this.$refs.previewIframe.contentWindow.location.reload();
    },
    toggleSidebar(isActive) {
      this.isSidebarActive = isActive;
    },
    createAffiliateLink() {
      postAffiliateLink(
        this.affiliateCode,
        this.affiliateTitle,
        this.affiliateUrl
      ).then((response) => {
        this.$root.ssrData.affiliate_links.push(response);
        this.$modal.hide("new-affiliate-link");
      });
    },
    createSmartLink() {
      postSmartLink(this.title, this.url).then((response) => {
        this.$root.ssrData.smart_links.push(response);
        this.$modal.hide("new-smart-link");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "colors.scss";

.main-splitted {
  display: flex;
}

.modal-title {
  padding: 14px;
}

.side {
  background: $color-elements-0;
  bottom: 0;
  display: block;
  left: 0;
  overflow: auto;
  position: fixed;
  top: 0;
  width: 15%;
  z-index: 3;
}

.center {
  padding: 0;
  left: 15%;
  position: absolute;
  top: 56px;
  width: 50%;
}

.preview {
  background-color: #ffffff;
  border-left: 1px solid #e2e2ea;
  bottom: 0;
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 0;
  top: 0;
  width: 35%;
}

.mobile-topbar {
  align-items: center;
  background: $color-elements-900;
  display: none;
  grid-template-columns: 1fr 3fr 1fr;
  height: 56px;
  justify-items: center;
  padding: 0 20px;
  position: fixed;
  top: 0;
  width: 62.5%;
  z-index: 1;

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  .logo {
    a {
      display: flex;

      h5 {
        color: $color-elements-0;
      }
    }

    img {
      margin-right: 10px;
      width: 37px;
    }
  }

  .button-icon {
    color: $color-elements-0;
    justify-self: flex-start;
  }
}

.sidebar-mobile-overlay {
  background-color: rgba($color-elements-900, 0.2);
  height: 100%;
  opacity: 0;
  position: fixed;
  transition: opacity 0.05s ease-in;
  visibility: hidden;
  width: 100%;
  z-index: 2;
}

@media screen and (max-width: 1200px) {
  .side {
    display: none;
    min-width: 320px;
    top: 56px;
    width: 30%;
  }

  .center {
    left: 0;
    top: 112px;
    width: 62.5%;
  }

  .preview {
    width: 37.5%;
  }

  .mobile-topbar {
    display: grid;
  }

  .active-sidebar {
    .side {
      display: block;
    }

    .sidebar-mobile-overlay {
      visibility: visible;
      opacity: 1;
    }
  }
}

@media screen and (max-width: 768px) {
  .preview {
    display: none;
  }

  .center {
    width: 100%;
  }
}

.iframe-container {
  align-content: center;
  display: flex;
  flex: 1;
  justify-content: center;
  margin: 48px;
  position: relative;

  iframe {
    background-color: $color-elements-900;
    border: 10px solid $color-elements-900;
    border-radius: 50px;
    bottom: 0;
    position: absolute;
    top: 0;
    margin: auto;
  }
}

.my-link {
  font-size: 16px;
  font-weight: 600;
  margin: auto;
  padding: 20px 16px;
  text-align: center;
  width: 100%;
}

.my-link-header {
  margin-bottom: 8px;
}

.my-link-link {
  text-decoration: underline;
  margin-bottom: 24px;
  overflow: hidden;
}

.my-link-action {
  .button-outline {
    width: 100px;
  }

  > * {
    display: inline-block;

    + * {
      margin-left: 8px;
    }
  }
}
</style>
