<template>
  <main>
    <div class="container">
      <header>
        <aside>
          <a
            class="header-button-icon"
            v-show="displayShareButton"
            @click="share()"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7 23C4.79086 23 3 21.2091 3 19L3 13C3 10.7909 4.79086 9 7 9L8 9C8.55228 9 9 9.44771 9 10C9 10.5523 8.55228 11 8 11L7 11C5.89543 11 5 11.8954 5 13L5 19C5 20.1046 5.89543 21 7 21L17 21C18.1046 21 19 20.1046 19 19L19 13C19 11.8954 18.1046 11 17 11L16 11C15.4477 11 15 10.5523 15 10C15 9.44771 15.4477 9 16 9L17 9C19.2091 9 21 10.7909 21 13L21 19C21 21.2091 19.2091 23 17 23L7 23Z"
                fill="#171725"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.29289 6.70711C6.90237 6.31658 6.90237 5.68342 7.29289 5.29289L11.2929 1.29289C11.6834 0.902369 12.3166 0.902369 12.7071 1.29289L16.7071 5.29289C17.0976 5.68342 17.0976 6.31658 16.7071 6.70711C16.3166 7.09763 15.6834 7.09763 15.2929 6.70711L12 3.41421L8.70711 6.70711C8.31658 7.09763 7.68342 7.09763 7.29289 6.70711Z"
                fill="#171725"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 15C11.4477 15 11 14.5523 11 14L11 3C11 2.44771 11.4477 2 12 2C12.5523 2 13 2.44771 13 3L13 14C13 14.5523 12.5523 15 12 15Z"
                fill="#171725"
              />
            </svg>
          </a>
        </aside>
        <img
          :src="user.picture.small || user.picture.extraSmall"
          :alt="'Logo picture of ' + (title || user.name)"
        />
        <h1>{{ title || user.name }}</h1>
        <a
          class="header-link"
          v-if="url && url.link"
          :href="url.link"
          @click="trackClick()"
          rel="ugc"
          target="_blank"
          >{{ url.link.replace("http://", "").replace("https://", "") }}</a
        >
      </header>
      <section class="custom-links">
        <a
          v-for="link in links.slice().reverse()"
          v-bind:key="link.title"
          :href="link.link"
          @click="trackClick()"
          class="custom-link"
          rel="ugc"
          target="_blank"
          >{{ link.title }}</a
        >
      </section>
    </div>
    <footer>
      <span v-show="fallbackDisplay" class="subhead-2"
        >Share this page with your friends</span
      >
      <div v-show="fallbackDisplay">
        <facebook :url="webSiteUrl" scale="2"></facebook>
        <twitter :url="webSiteUrl" :title="webSiteTitle" scale="2"></twitter>
        <linkedin :url="webSiteUrl" scale="2"></linkedin>
        <telegram :url="webSiteUrl" scale="2"></telegram>
        <br />
        <whats-app
          :url="webSiteUrl"
          :title="webSiteTitle"
          scale="2"
        ></whats-app>
        <pinterest :url="webSiteUrl" scale="2"></pinterest>
        <reddit :url="webSiteUrl" scale="2" :title="webSiteTitle"></reddit>
        <email :url="webSiteUrl" :subject="webSiteTitle" scale="2"></email>
      </div>
      <span class="subhead-2">Want to create your own page? It's free!</span>
      <a
        href="https://bnd.link/"
        target="_blank"
        ref="noopener"
        class="button-outline"
        onclick="ga('send', 'event', 'Landing', 'Open', 'WantToCreateYourOwnPage')"
      >
        Get Started
      </a>
    </footer>
  </main>
</template>

<script>
import { getPage, trackClick, trackPageView } from "./Ajax.js";
import {
  Facebook,
  Twitter,
  Linkedin,
  Pinterest,
  Reddit,
  Telegram,
  WhatsApp,
  Email,
  Google,
} from "vue-socialmedia-share";

export default {
  components: {
    Facebook,
    Twitter,
    Linkedin,
    Pinterest,
    Reddit,
    Telegram,
    WhatsApp,
    Email,
    Google,
  },
  computed: {
    isOwnPage() {
      return (
        this.$root.ssrData.isOwnPage && this.$route.query.preview === undefined
      );
    },
    user() {
      return this.$root.ssrData.profile;
    },
    links() {
      return this.$root.ssrData.smart_link.links;
    },
    title() {
      return this.$root.ssrData.smart_link.name;
    },
    tracker() {
      return this.$root.ssrData.smart_link.tracker;
    },
    bandlab_account() {
      return this.$root.ssrData.bandlab_account;
    },
    url() {
      return this.$root.ssrData.url;
    },
    webSiteTitle() {
      return this.webSiteTitle;
    },
    webSiteUrl() {
      return this.webSiteUrl;
    },
  },
  data() {
    return {
      displayShareButton: this.$root.ssrData.displayShareButton,
      fallbackDisplay: false,
      webSiteTitle: this.$root.ssrData.title,
      webSiteUrl: window.location.href,
    };
  },
  mounted() {
    if (this.$route.query.preview === undefined) {
      trackPageView(this.tracker);
    }

    // Handle page differently if it has a page ID
    if (this.$root.ssrData.pageID) {
      getPage(this.$root.ssrData.pageID).then((page) => {
        this.$root.ssrData.links = page.links;
      });
    }
  },
  methods: {
    share() {
      if (this.$route.query.preview !== undefined) {
        return;
      }

      ga("send", "event", "ShareButton", "Click", "Account");
      if (navigator.share) {
        navigator
          .share({
            title: this.webSiteTitle,
            url: this.webSiteUrl,
          })
          .catch(console.error);
      } else {
        this.fallbackDisplay = !this.fallbackDisplay;
      }
    },
    trackClick() {
      if (this.$route.query.preview === undefined) {
        ga("send", "event", "Link", "Click", "Account");
        trackClick(this.tracker);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
main {
  background: var(--background);
  display: flex;
  flex-direction: column;
  padding: 32px 0 16px;
  text-align: center;
}

header {
  position: relative;

  h1 {
    color: var(--title-color);
    font-size: 24px;
    margin: 16px auto 24px;
  }

  img {
    background: #fff;
    border-radius: 50%;
    border: 2px solid #fff;
    height: 80px;
    width: 80px;
  }
}

.header-link {
  color: var(--title-color);
  display: block;
  font-size: 16px;
  line-height: 24px;
  margin: -24px 0 24px;
}

.header-button-icon {
  align-items: center;
  background-color: var(--footer-background-color);
  border-radius: 8px;
  display: inline-flex;
  height: 40px;
  justify-content: center;
  padding: 8px;
  width: 40px;

  & + & {
    margin-left: 8px;
  }
}

aside {
  display: flex;
  position: absolute;
  right: 0;
}

.container {
  flex: 1;
  margin-bottom: 48px;
  margin: 0 auto;
  max-width: 640px;
  padding: 0 16px;
  width: 100%;
}

.custom-link {
  background-color: var(--custom-link-background-color);
  border-radius: 8px;
  border: 2px solid var(--custom-link-border-color);
  color: var(--custom-link-color);
  display: block;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  overflow-wrap: break-word;
  padding: 10px 16px;
  text-align: center;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  &:last-child {
    margin-bottom: 24px;
  }
}

.sns-links {
  margin-top: 24px;
}

.sns-link {
  color: var(--sns-link-color);
  display: inline-block;
  margin: 0 12px 24px;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  path,
  polygon {
    fill: var(--sns-link-color);
  }
}

.header-button-icon {
  background-color: var(--footer-background-color);
  position: relative;
  top: -16px;

  path,
  polygon {
    fill: var(--footer-color);
  }
}
</style>
