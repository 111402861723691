import Vue from "vue";
import VueRouter from "vue-router";
import VModal from "vue-js-modal";

import App from "./App.vue";
import Admin from "./Admin.vue";
import Account from "./Account.vue";
import AccountEdit from "./AccountEdit.vue";
import AccountPage from "./AccountPage.vue";
import AccountSettings from "./AccountSettings.vue";
import AccountStats from "./AccountStats.vue";
import SmartLinkPage from "./SmartLinkPage.vue";
import AffiliateLinkPage from "./AffiliateLinkPage.vue";
import AlertModal from "./AlertModal.vue";
import CustomModal from "./CustomModal.vue";
import Main from "./Main.vue";
import Preview from "./Preview.vue";
import SmartLinkPageEdit from "./SmartLinkPageEdit.vue";

Vue.use(VueRouter);
Vue.use(VModal);
Vue.component("alert-modal", AlertModal);
Vue.component("custom-modal", CustomModal);

const data = {
  ssrData: window.ssrData,
};

const router = new VueRouter({
  mode: "history",
  routes: [
    { path: "/", name: "root", component: Main },
    {
      path: "/:user",
      name: "account",
      component: Account,
    },
    {
      path: "/u",
      component: Admin,
      children: [
        {
          path: "affiliate-link/:code",
          name: "affiliate-link",
          component: AffiliateLinkPage,
        },
        {
          path: "smart-link/:id",
          name: "smart-link",
          component: SmartLinkPageEdit,
        },
        { path: "edit", name: "account-edit", component: AccountEdit },
        {
          path: "settings",
          name: "account-settings",
          component: AccountSettings,
        },
        { path: "info", name: "account-info", component: AccountStats },
        { path: "preview", name: "account-preview", component: Preview },
        { path: "pages/:id", name: "account-page", component: AccountPage },
      ],
    },
    {
      path: "/:user/smart-link/:id",
      name: "account",
      component: SmartLinkPage,
    },
  ],
});

new Vue({
  data,
  el: "#root",
  router,
  template: "<App />",
  components: { App },
});
