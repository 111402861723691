<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 22 22"
    version="1.2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7 0H15C18.866 0 22 3.13401 22 7V15C22 18.866 18.866 22 15 22H7C3.13401 22 0 18.866 0 15V7C0 3.13401 3.13401 0 7 0ZM7 2C4.23858 2 2 4.23858 2 7V15C2 17.7614 4.23858 20 7 20H15C17.7614 20 20 17.7614 20 15V7C20 4.23858 17.7614 2 15 2H7Z"
      fill="#171725"
    ></path>
    <path
      class="tick"
      d="M9.96274 12.5203L7.69163 10.2784C7.29858 9.89043 6.66543 9.89453 6.27744 10.2876C5.88945 10.6806 5.89355 11.3138 6.2866 11.7018L9.33575 14.7117C9.75377 15.1243 10.4357 15.0895 10.8096 14.6364L15.7604 8.63645C16.1119 8.21046 16.0516 7.58018 15.6256 7.22868C15.1996 6.87718 14.5693 6.93756 14.2178 7.36355L9.96274 12.5203Z"
      fill="#171725"
    ></path>
  </svg>
</template>
<script>
export default {};
</script>
